import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppearanceSSS, BasicSSS, LanguageSSS, RespondentSSS, TextSSS } from '../../app-admin/models';
import { String } from 'typescript-string-operations-ng4';
import { ResourcesConsts } from '../../app-admin/consts/resources.consts';
import {
    AppearanceSSSModel,
    BasicSSSModel,
    createLanguageList,
    LanguageItemModel,
    LanguageSSSModel,
    RespondentSSSModel,
    ResponseLimitsSSS,
    ResponseLimitsSSSModel,
    SurveySelectedLanguage,
    SurveyText,
    SurveyTextModel,
    TextSSSModel,
    LanguageItem,
    SurveySettingsModel
} from '../models/survey-settings.model';
import { FileExportProvider } from './file-export.provider';
import { of } from 'rxjs';

@Injectable()
export class SurveysSettingProvider {
    storedLanguageSettings: BehaviorSubject<LanguageItemModel>;
    storedAppearanceSettings: BehaviorSubject<
        Partial<AppearanceSSSModel>
    > = new BehaviorSubject({});
    surveySettings: BehaviorSubject<Partial<BasicSSSModel>> = new BehaviorSubject({}) ;
    constructor(private $http: HttpClient, private fileExportProvider: FileExportProvider) {}

    getBaseSettingsById(surveyId: string): Observable<BasicSSSModel> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_SETTINGS_BASICS,
            surveyId
        );
        return this.$http.get(url).pipe(
            map((data: any) => {
                this.surveySettings.next(new BasicSSSModel(data));
                return new BasicSSSModel(data);
            })
        );
    }

    getSurveyById(surveyId: string) {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_SETTINGS_BASICS,
            surveyId
        );
        return this.$http
            .get(url);
    }

    getSurveyWideScript(surveyId: string, isTemplate = false): Observable<string> {
        const url: string = isTemplate ? String.Format(ResourcesConsts.SURVEY_TEMPLATE_SETTINGS_SCRIPTS, surveyId) :
                                        String.Format(ResourcesConsts.SURVEY_SETTINGS_SCRIPTS, surveyId);
        return this.$http
            .get<string>(url);
    }

    putSurveyWideScript(surveyId: string, scripts: string, isTemplate = false): Observable<string> {
        const url: string = isTemplate ? String.Format(ResourcesConsts.SURVEY_TEMPLATE_SETTINGS_SCRIPTS, surveyId) :
                                         String.Format(ResourcesConsts.SURVEY_SETTINGS_SCRIPTS, surveyId);
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.$http
            .put<string>(url, JSON.stringify(scripts), { headers: headers });
    }

    getSurveyWideCSS(surveyId: string, isTemplate = false): Observable<string> {
        const url: string = isTemplate ? String.Format(ResourcesConsts.SURVEY_TEMPLATE_SETTINGS_CSS, surveyId) :
                                         String.Format(ResourcesConsts.SURVEY_SETTINGS_CSS, surveyId);
        return this.$http
            .get<string>(url);
    }

    putSurveyWideCSS(surveyId: string, css: string, isTemplate = false): Observable<string> {
        const url: string = isTemplate ? String.Format(ResourcesConsts.SURVEY_TEMPLATE_SETTINGS_CSS, surveyId) :
                                         String.Format(ResourcesConsts.SURVEY_SETTINGS_CSS, surveyId);
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.$http
            .put<string>(url, JSON.stringify(css), { headers: headers });
    }

    getSurveyWideReferences(surveyId: string, isTemplate = false): Observable<string> {
        const url: string = isTemplate ? String.Format(ResourcesConsts.SURVEY_TEMPLATE_SETTINGS_REFERENCES, surveyId) :
                                         String.Format(ResourcesConsts.SURVEY_SETTINGS_REFERENCES, surveyId);
        return this.$http
            .get<string>(url);
    }

    putSurveyWideReference(surveyId: string, html: string, isTemplate = false): Observable<string> {
        const url: string = isTemplate ? String.Format(ResourcesConsts.SURVEY_TEMPLATE_SETTINGS_REFERENCES, surveyId) :
                                         String.Format(ResourcesConsts.SURVEY_SETTINGS_REFERENCES, surveyId);
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.$http
            .put<string>(url, JSON.stringify(html), { headers: headers });
    }

    putBaseSettingsById(
        surveyId: string,
        settings: BasicSSS
    ): Observable<BasicSSSModel> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_SETTINGS_BASICS,
            surveyId
        );
        return this.$http.put(url, settings).pipe(
            map((data: any) => new BasicSSSModel(data))
        );
    }

    getSurveySettings(
        surveyId: string
    ): Observable<SurveySettingsModel> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_SETTINGS,
            surveyId
        );
        return this.$http.get(url).pipe(
            map((data: any) => {
                const settings = new SurveySettingsModel(data);
                this.storedAppearanceSettings.next(settings.appearanceSettings);
                return settings;
             })
        );
    }

    getAppearanceSettingsById(
        surveyId: string
    ): Observable<AppearanceSSSModel> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_SETTINGS_APPEARANCE,
            surveyId
        );
        return this.$http.get(url).pipe(
            map((data: any) => {
                this.storedAppearanceSettings.next(
                    new AppearanceSSSModel(data)
                );
                return new AppearanceSSSModel(data);
            })
        );
    }

    putAppearanceSettingsById(
        surveyId: string,
        settings: AppearanceSSS
    ): Observable<AppearanceSSSModel> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_SETTINGS_APPEARANCE,
            surveyId
        );
        return this.$http.put(url, settings).pipe(
            map((data: any) => new AppearanceSSSModel(data))
        );
    }

    getRespondentSettingsById(
        surveyId: string
    ): Observable<RespondentSSSModel> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_SETTINGS_RESPONDENT,
            surveyId
        );
        return this.$http.get(url).pipe(
            map((data: any) => new RespondentSSSModel(data))
        );
    }

    putRespondentSettingsById(
        surveyId: string,
        settings: RespondentSSS
    ): Observable<RespondentSSSModel> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_SETTINGS_RESPONDENT,
            surveyId
        );
        return this.$http.put(url, settings).pipe(
            map((data: any) => new RespondentSSSModel(data))
        );
    }

    postMakeAllQuestionsRequired(
        surveyId: string
    ) {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_SETTINGS_MAKE_ALL_REQUIRED,
            surveyId
        );
        return this.$http.post(url, {});
    }

    getResponseLimitSettingsById(
        surveyId: string
    ): Observable<ResponseLimitsSSSModel> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_SETTINGS_RESPONSE_LIMIT,
            surveyId
        );
        return this.$http.get(url).pipe(
            map((data: any) => new ResponseLimitsSSSModel(data))
        );
    }

    putResponseLimitSettingsById(
        surveyId: string,
        settings: ResponseLimitsSSS
    ): Observable<ResponseLimitsSSSModel> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_SETTINGS_RESPONSE_LIMIT,
            surveyId
        );
        return this.$http.put(url, settings).pipe(
            map((data: any) => new ResponseLimitsSSSModel(data))
        );
    }

    getLanguages(): Observable<LanguageItem[]> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_SETTINGS_DICT_LANGUAGES
        );
        return this.$http.get(url).pipe(
            map((data: any) => createLanguageList(data))
        );
    }

    getLanguageSettingsById(surveyId: string, isTemplate = false): Observable<LanguageSSSModel> {
        const url: string = isTemplate ? String.Format( ResourcesConsts.SURVEY_TEMPLATE_SETTINGS_LANGUAGE, surveyId) :
                                         String.Format( ResourcesConsts.SURVEY_SETTINGS_LANGUAGE, surveyId);
        return this.$http.get(url).pipe(
            map((data: any) => new LanguageSSSModel(data))
        );
    }

    getLanguageSettingsByExternalId(externalId: string): Observable<LanguageSSSModel> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_SETTINGS_LANGUAGE_BY_EXTERNAL_ID,
            externalId
        );
        return this.$http.get(url).pipe(
            map((data: any) => new LanguageSSSModel(data))
        );
    }

    putLanguageSettingsById(
        surveyId: string,
        settings: LanguageSSS,
        isTemplate = false
    ): Observable<LanguageSSSModel> {
        const url: string = isTemplate ? String.Format( ResourcesConsts.SURVEY_TEMPLATE_SETTINGS_LANGUAGE, surveyId) :
                                         String.Format( ResourcesConsts.SURVEY_SETTINGS_LANGUAGE, surveyId);
        return this.$http.put(url, settings).pipe(
            map((data: any) => new LanguageSSSModel(data))
        );
    }

    getTextSettingsById(surveyId: string): Observable<TextSSSModel> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_SETTINGS_TEXT,
            surveyId
        );
        return this.$http.get(url).pipe(
            map((data: any) => new TextSSSModel(data))
        );
    }

    putTextSettingsById(
        surveyId: string,
        settings: TextSSS
    ): Observable<TextSSSModel> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_SETTINGS_TEXT,
            surveyId
        );
        return this.$http.put(url, settings).pipe(
            map((data: any) => new TextSSSModel(data))
        );
    }

    getSurveyTextById(
        surveyId: any,
        languageCode: string
    ): Observable<SurveyTextModel> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_TEXT,
            surveyId,
            languageCode
        );
        return this.$http.get(url).pipe(
            map((data: any) => new SurveyTextModel(data))
        );
    }

    getSurveyTextByExternalId(
        externalId: any,
        languageCode: string
    ): Observable<SurveyTextModel> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_TEXT_BY_EXTERNAL_ID,
            externalId,
            languageCode
        );
        return this.$http.get(url).pipe(
            map((data: any) => new SurveyTextModel(data))
        );
    }

    putSurveyTextById(
        surveyId: string,
        settings: SurveyText
    ): Observable<SurveyTextModel> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_TEXT,
            surveyId,
            settings.languageCode
        );
        return this.$http.put(url, settings).pipe(
            map((data: any) => new SurveyTextModel(data))
        );
    }

    getSurveyQuestions(surveyId: string, pageId: number): Observable<any> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_QUESTIONS_TEXT,
            surveyId
        );
        return this.$http
            .get(`${url}?page_id=${pageId}&include_current_page=false`);
    }

    shareCurrentSurveySelectedLanguage(language: SurveySelectedLanguage) {
        if (this.storedLanguageSettings) {
            this.storedLanguageSettings.next(language);
        } else {
            this.storedLanguageSettings = new BehaviorSubject(language);
        }
    }

    getCurrentSurveySelectedLanguage() {
        if (this.storedLanguageSettings) {
            return this.storedLanguageSettings;
        } else {
            this.storedLanguageSettings = new BehaviorSubject(null);
            return this.storedLanguageSettings;
        }
    }

    validateSurveyPassword(surveyId: number, password: string) {
        const headers = new HttpHeaders().set(
            'Content-Type',
            'application/json'
        );
        const url: string = String.Format(
            ResourcesConsts.SURVEY_VALIDATE_PASSWORD,
            surveyId
        );
        return this.$http
            .post(url, JSON.stringify(password), { headers });
    }

    getSurveyTextExport(surveyId: any) {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_TEXT,
            surveyId,
            'export'
        );
        return this.fileExportProvider.getFileFromBackend(url);
    }

    getSurveyGoogleAnalyticsId(surveyId: string, isTemplate = false): Observable<string> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_GOOGLE_ANALYTICS_ID,
            surveyId
        );
        return isTemplate ? of(null) : this.$http
            .get<string>(url);
    }

    putSurveyGoogleAnalyticsId(
        surveyId: string,
        html: string
    ): Observable<string> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_GOOGLE_ANALYTICS_ID,
            surveyId
        );
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.$http
            .put<string>(url, JSON.stringify(html), { headers: headers });
    }


    getSurveyGoogleTagManagerAnalyticsId(surveyId: string, isTemplate = false): Observable<string> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_GOOGLE_TAG_MANAGER_ID,
            surveyId
        );
        return isTemplate ? of(null) : this.$http
            .get<string>(url);
    }

    putSurveyGoogleTagManagerAnalyticsId(
        surveyId: string,
        html: string
    ): Observable<string> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_GOOGLE_TAG_MANAGER_ID,
            surveyId
        );
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.$http
            .put<string>(url, JSON.stringify(html), { headers: headers });
    }
}
