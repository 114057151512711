import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SurveyPage } from '../../../../../app-admin/models';
import {
    SurveyActionType,
    SurveyDisplayType,
    SurveyQuestionType,
    SurveySliderValueType,
    SurveysPageTypes
} from '../../../../../infrastructure/consts/surveys.consts';
import { AccountNameResolverProvider, StorageProvider } from '../../../../../infrastructure/providers';
import { EnvironmentProvider } from '../../../../../infrastructure/providers/environment.provider';
import * as _ from 'lodash';
import { stripHtml } from '../../../../../infrastructure/helpers/strip-html.helper';
import { environment } from '../../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { Response } from '../../../../../infrastructure/models/response/response.model';
import { SurveyEditorProvider } from '../../../../../app-admin/providers';
import { TakeSurveyPipingService } from '../../../../../infrastructure/services/take-survey-piping.service';
import { ContactFormField, ContactFormItem } from '../../../../models/survey-items/question-items/contactFormItem';

@Component({
    selector: 'cb-survey-response-details',
    templateUrl: './survey-response-details.component.html',
    styleUrls: ['./survey-response-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SurveyResponseDetailsComponent implements OnInit {
    @Input() pages: SurveyPage[];
    @Input() response: Response;
    @Input() surveyId: string;

    @Input() showDisplayMessages = true;
    @Input() showItemNumeration = true;
    @Input() showPagesNumeration = true;
    @Input() showHiddenItems = true;
    @Input() showFileUploads = true;
    @Input() showPoints = false;

    @Output() onLoad = new EventEmitter<void>();
    questionType = SurveyQuestionType;
    surveyDisplayType = SurveyDisplayType;
    SurveysPageTypes = SurveysPageTypes;

    sliderValueType = SurveySliderValueType;
    accountName: string;
    message: string;
    score: number;
    stripHtml = stripHtml;

    naText: string;
    isLoading = true;
    isPrintMode = false;
    _pages: SurveyPage[];

    constructor(
        private storageProvider: StorageProvider,
        private environmentProvider: EnvironmentProvider,
        private takeSurveyAccountNameProvider: AccountNameResolverProvider,
        private translateService: TranslateService,
        private surveyEditorProvider: SurveyEditorProvider,
        private takeSurveyPipingService: TakeSurveyPipingService,
        private _cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.isLoading = true;
        const accountReq = environment.isAdminSite
            ? this.storageProvider.getAccount()
            : this.takeSurveyAccountNameProvider.getTakeSurveyAccount();

        const translateReq = this.translateService
            .get('SURVEY-RESPONSES.RESPONSE-PREVIEW.NOT-AVAILABLE');
        this._pages = this.filterPages(this.pages, this.response);

        const itemsReq = this.prepareQuestionsRequests(this._pages, this.surveyId);
        this.isPrintMode = window.location.href.indexOf('/surveys/print-response') > -1;

        forkJoin([accountReq, translateReq, ...itemsReq]).subscribe(([account, translation, ...items]) => {
            this.accountName = account;
            this.naText = translation;
            this._pages = this.preparePagesForResponseSummaryItem(this._pages, this.response, items);
            this.isLoading = false;
            if (!this._cdr['destroyed']) {
                this._cdr.detectChanges();
            }
            this.onLoad.emit();
        }, err => this.errorHandler(err));
    }

    filterPages(pages, response): SurveyPage[] {
        const completedPages = _.chain(response)
            .get('pages')
            .filter({ excluded: false })
            .map('page_id')
            .value();
        return _.chain(pages)
            .cloneDeep()
            .filter(page => this.pageFilter(page, completedPages))
            .value();
    }

    prepareQuestionsRequests(pagesData, surveyId) {
        return _.map(pagesData, page =>
            this.surveyEditorProvider.getQuestions(surveyId, page.id)
        );
    }

    pageFilter(page, completedPages) {
        let hiddenItemsFilter: boolean;
        if (this.showHiddenItems) {
            hiddenItemsFilter = page.page_type !== SurveysPageTypes.Completion;
        } else {
            hiddenItemsFilter = page.page_type === SurveysPageTypes.ContentPage;
        }
        const completedPageFilter = completedPages.indexOf(page.id) !== -1;
        const excludedFilter = !page.excluded;
        return hiddenItemsFilter && completedPageFilter && excludedFilter;
    }

    itemFilter(item) {
        const displayTypes = [], actionTypes = [];
        for (const prop in SurveyDisplayType) {
            if (SurveyDisplayType.hasOwnProperty(prop)) {
                displayTypes.push(SurveyDisplayType[prop]);
            }
        }
        for (const prop in SurveyActionType) {
            if (SurveyActionType.hasOwnProperty(prop)) {
                actionTypes.push(SurveyActionType[prop]);
            }
        }
        const passDisplayTypeFilter = displayTypes.indexOf(item.item_type) === -1 || this.showDisplayMessages;
        const passActionTypeFilter = actionTypes.indexOf(item.item_type) === -1;
        return passDisplayTypeFilter && passActionTypeFilter && !item.excluded;
    }

    filterMatrixRows(item) { // filter excluded matrix rows
        if (_.isArray(item.excluded_rows) && item.excluded_rows.length) {
            item.rows = _.filter(item.rows, row => item.excluded_rows.indexOf(row.id));
        }
        return item;
    }

    preparePagesForResponseSummaryItem(pages, response, pagesItems) {
        const responseItems = _.chain(response)
            .get('pages')
            .map('items')
            .flatten()
            .keyBy('item_id')
            .value();

        const pipeValues = _.chain(response)
            .get('pages')
            .keyBy('page_id')
            .mapValues('pipe_values')
            .value();
        return _.map(pages, (page: any, i) => {
            page.items = _.chain(pagesItems[i])
                .map(pageItem => {
                    return _.assign(pageItem, responseItems[pageItem.id]); // add response to item info
                })
                .filter(item => this.itemFilter(item)) // filter excluded items and display items is corresponding option is selected
                .map(item => this.filterMatrixRows(item)) // filter excluded matrix rows
                .value();
            page.pipe_values = pipeValues[page.id];
            this.takeSurveyPipingService.replacePipingCodes(page);
            return page;
        });
    }

    getPageLabel(page: SurveyPage) {
        if (
            this.showPagesNumeration &&
            page.page_type !== SurveysPageTypes.HiddenItems
        ) {
            return page.title
                ? `Page ${page.position - 1} - ${page.title}`
                : `Page ${page.position - 1}`;
        } else {
            return page.title;
        }
    }

    getTextAnswer(item) {
        return item.answer.text;
    }

    getCheckboxesAnswer(item, itemAnswer) {
        if (!item || !itemAnswer || !itemAnswer.choices || !itemAnswer.choices.length) {
            return '';
        }

        const choices = _.keyBy(item.choices, 'id');
        return itemAnswer.choices
            .map(answerChoice => {
                const selectedChoice = answerChoice.choice_id ? choices[answerChoice.choice_id] : null;
                if (!selectedChoice) {
                    return '';
                }

                if (selectedChoice.is_other && answerChoice.text) {
                    return answerChoice.text;
                }

                const choiceText = selectedChoice.text || '';
                return this.showPoints && selectedChoice.points != null
                    ? `${choiceText} (${selectedChoice.points})`
                    : choiceText;
            })
            .join(', ');
    }

    getRankOrderTextAnswer(item) {
        return _.chain(item.answer)
            .get('choices', [])
            .map(choice => {
                return this.getChoice(item, choice.choice_id).text;
            })
            .join(', ')
            .value();
    }

    getImageAnswer(item, page) {
        return _.chain(item.answer)
            .get('choices', [])
            .map(choice => {
                return this.getChoice(item, choice.choice_id);
            })
            .map(choice => {
                return choice.image && choice.image.file_url;
            })
            .value();
    }

    getUploadedFileCaption(item) {
        if (item.answer.file && item.answer.file.file_name) {
            return `${item.answer.file.file_name} - ${item.answer.file
                .content_size / 1000} KB`;
        }
    }

    getFilePath(item) {
        const respondentFilesPath = `surveys/${this.surveyId}/responses/${this.response.id}/files`;
        const fileContentPath = `${respondentFilesPath}/${item.answer.file.id}/content`;
        return environment.isMultiTenant
            ? `${this.environmentProvider.apiUrl}/${this.accountName}/${fileContentPath}`
            : `${this.environmentProvider.apiUrl}/${fileContentPath}`;
    }

    getDrillDownAnswer(item, itemAnswer) {
        return itemAnswer.text;
    }

    getSliderAnswer(item) {
        if ( item.value_type === this.sliderValueType.NUMBER_RANGE) {
            return item.answer.value;
        }
        return item.answer.choice_id ? this.getChoice(item, item.answer.choice_id).text : '';
    }

    getSingleChoiceValueText(item, itemAnswer) {
        if (itemAnswer.text) {
            return itemAnswer.text;
        }
        if (itemAnswer.value) {
            return itemAnswer.value;
        }

        const selectedChoice = item && itemAnswer.choice_id ? this.getChoice(item, itemAnswer.choice_id) : null;
        if (!selectedChoice) {
            return '';
        }

        const selectedChoiceText = selectedChoice.text || '';
        const points = this.showPoints ? selectedChoice.points : null;
        switch (item.item_type) {
            case this.questionType.NET_PROMOTER_SCORE:
                const message = this.getNetPromoterScoreMessage(selectedChoice.points);
                return points != null
                    ? `${message} ${selectedChoiceText} (${points})`
                    : `${message} ${selectedChoiceText}`;
            case this.questionType.RATINGSCALE:
                // TODO: take into account rating scale label type
                const rsChoiceText = selectedChoice.text || selectedChoice.is_other
                    ? selectedChoiceText
                    : selectedChoice.points + '';
                 return points != null && !selectedChoice.is_other
                    ? `${rsChoiceText} (${points})`
                    : rsChoiceText;
            default:
                return points != null
                    ? `${selectedChoiceText} (${points})`
                    : selectedChoiceText;
        }
    }

    getSingleChoiceValueImage(item) {
        const choiceId = _.get(item, 'answer.choice_id');
        if (choiceId) {
            return _.get(this.getChoice(item, choiceId), 'image.file_url');
        }
    }

    getMaxDiffLabels(item) {
        return _.chain(item.orientation)
            .split('_')
            .map(orientation => item[orientation.toLowerCase() + '_label'])
            .value();
    }

    getNetPromoterScoreMessage(points: number) {
        if (_.inRange(points, 0, 7)) {
            return 'Detractor';
        } else if (_.inRange(points, 7, 9)) {
            return 'Passive';
        } else if (_.inRange(points, 9, 11)) {
            return 'Promoter';
        } else {
            return '';
        }
    }

    getChoice(item, id) {
        return _.find(item.choices, { id }) || {text: this.naText} as any;
    }

    getMaxDiffChoices(item, setIndex) {
        const dataSets = item.dataSets || item.data.sets;
        const itemChoices = [...item.choices]; // way to make a copy
        return _.chain(dataSets)
            .find(x => x.set_number === setIndex)
            .get('choices')
            .map(ch => itemChoices.find(x => x.id === ch))
            .value();
    }

    getMaxDiffChoiceRow(choice, set, item) {
        const rowLabels = {
            WORST: choice && choice.id === set.worst_choice_id ? 'Yes' : 'No',
            BEST: choice && choice.id === set.best_choice_id ? 'Yes' : 'No',
            ITEM: choice ? choice.text : this.naText
        };
        return item.orientation.split('_').map(rowLabel => rowLabels[rowLabel]);
    }

    getElementsForRow(matrixItem, row) {
        const subItems = _.keyBy(matrixItem.subitems, 'item_id');
        const prototypeItems = _.chain(matrixItem.columns)
            .map('prototype_item')
            .compact()
            .keyBy('id')
            .value();

        return matrixItem.elements
            .filter(element => element.row === row)
            .sort((a, b) => a.column - b.column)
            .map(element => {
                const item = element.item;
                item.prototype_item = prototypeItems[item.prototype_item_id];
                item.answer = subItems[item.id] && subItems[item.id].answer;
                return item;
            });
    }

    getSortedColumns(columns) {
        return columns.sort((a, b) => a.position - b.position);
    }

    getSortedRows(rows) {
        return rows.sort((a, b) => a.position - b.position);
    }

    getMatrixRowContent(item) {
        if (item.item_type === SurveyDisplayType.MESSAGE) {
            return item.text;
        }

        if (item.answer) {
            if (item.item_type === SurveyQuestionType.SINGLE_LINE_TEXT) {
                return item.answer.text;
            }

            switch (item.prototype_item_type) {
                case SurveyQuestionType.TEXT:
                case SurveyQuestionType.SINGLE_LINE_TEXT:
                case SurveyQuestionType.SUM_TOTAL:
                    return item.answer.text;
                case SurveyQuestionType.RATINGSCALE:
                case SurveyQuestionType.SLIDER:
                case SurveyQuestionType.DROPDOWNLIST:
                case SurveyQuestionType.RADIOBUTTONS:
                    return this.getSingleChoiceValueText(item.prototype_item, item.answer);
                case SurveyQuestionType.CHECKBOXES:
                    return this.getCheckboxesAnswer(item.prototype_item, item.answer);
                case SurveyQuestionType.CUSTOM_SOURCE_DROPDOWNLIST:
                    return item.answer.value;
                default:
                    break;
            }
        } else {
            return '';
        }
    }

    getSortedContactFormFields(contactFormItem: ContactFormItem) {
        return contactFormItem.fields
            .filter(f => {
                const responseSubitem = contactFormItem.subitems.find(si => si.item_id == f.question.id);
                return responseSubitem
                    ? !responseSubitem.excluded && (f.enabled || responseSubitem.answer)
                    : f.enabled;
            })
            .sort((a, b) => a.position - b.position);
    }

    getContactFormFieldAnswerText(contactFormItem: ContactFormItem, field: ContactFormField) {
        const responseSubitem = contactFormItem.subitems.find(si => si.item_id == field.question.id);
        if (!responseSubitem || !responseSubitem.answer || responseSubitem.excluded) {
            return '';
        }

        return field.question.item_type === SurveyQuestionType.SINGLE_LINE_TEXT
            ? responseSubitem.answer.text
            : '';
    }

    errorHandler(error) {
        this.isLoading = false;
    }
}
