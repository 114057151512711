import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Observable } from 'rxjs';

import { ErrorMessage } from '../../infrastructure/consts/error.consts';
import { ApplicationError, ClientUnexpectedError } from '../../infrastructure/models';
import { ErrorStore } from '../../infrastructure/store';
import { ToasterService, PrintService, PrintMode } from '../../infrastructure/services';
import { version } from '../../../package.json';
import { RouteConfigLoadEnd, RouteConfigLoadStart, Router, RouterEvent } from '@angular/router';

@Component({
    selector: 'cb-app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    private lastError: Observable<ApplicationError>;
    isChangingRoute: boolean;
    isPrintReport: boolean;

    constructor(
        private errorStore: ErrorStore,
        private toasterService: ToasterService,
        private printService: PrintService,
        private cdr: ChangeDetectorRef,
        private router: Router
    ) {
        this.lastError = this.errorStore.lastError;
        router.events.subscribe((event: RouterEvent) => {
            if (event instanceof RouteConfigLoadStart) {
                this.isChangingRoute = true;
            }
            if (event instanceof RouteConfigLoadEnd) {
                this.isChangingRoute = false;
            }
        });
    }

    ngOnInit() {
        this.printVersionToConsole();
        this.lastError.subscribe(err => {
            this.handleLastError(err);
        });

        this.printService.printMode.subscribe(printMode => {
            this.isPrintReport = printMode == PrintMode.REPORT;
            this.cdr.detectChanges();
        });
    }

    private printVersionToConsole(): void {
        console.log(`Checkbox Version ${version}`);
    }

    private handleLastError(error) {
        if (error instanceof ClientUnexpectedError) {
            console.error(error);
            const message = ErrorMessage.CLIENT_ERROR_MESSAGE;
            this.toasterService.showError({ message, fullError: error.stack_trace });
        }
    }
}
