import { UsersProvider } from './contacts.provider';
import { ExportContactsProvider } from './export-contacts.provider';
import { GroupsProvider } from './groups.provider';
import { AclProvider } from './acl.provider';
import { ZendeskSsoProvider } from './support/zendesk-sso.provider';
import { SurveysProvider } from './surveys.provider';
import { FoldersProvider } from './surveys/survey-folders.provider';
import { SurveyListProvider } from './surveys/survey-list.provider';
import { StyleTemplatesProvider } from '../../infrastructure/providers/style-templates.provider';
import { LimitsProvider } from '../../infrastructure/providers/limits.provider';
import { SurveyEditorProvider } from './survey-editor/survey-editor.provider';
import { AutocompleteListProvider } from './survey-editor/autocomplete-list.provider';
import { FileExtensionsProvider } from './file-extensions.provider';
import { SurveyMergeCodesProvider } from './survey-merge-codes/survey-merge-codes.provider';
import { SurveysSettingProvider } from '../../infrastructure/providers/surveys-setting.provider';
import { AdminAppFileUploadProvider } from './admin-app-file-upload.provider';
import { TakeSurveyProvider } from '../../infrastructure/providers/take-survey.provider';
import { LoginProvider } from '../../infrastructure/providers/login.provider';
import { DashboardsProvider } from '../../infrastructure/providers/dashboards/dashboards.provider';
import { SurveyResponseProvider } from '../../infrastructure/providers/responses/survey-response.provider';
import { ResponseExportSettingsProvider } from './application-settings/response-export-settings-provider.service';
import { ResetPasswordProvider } from '../../infrastructure/providers/reset-password.provider';
import { DatePipe } from '@angular/common';
import { FileExportProvider } from '../../infrastructure/providers/file-export.provider';
import { SurveyInvitationProvider } from '../../infrastructure/providers/survey-invitation.provider';
import { SurveyPagesProvider } from '../../infrastructure/providers/surveys/survey-pages.provider';
import { SurveyTemplatesProvider } from './survey-templates/survey-templates.provider';
import { MyAccountInfoProvider } from './support/my-account-info-provider';
import { SurveyExpressionsProvider } from './survey-expressions/survey-expressions.provider';
import { DashboardExpressionsProvider } from '../../infrastructure/providers/dashboards-expressions/dashboard-expressions.provider';
import { EmailVerificationProvider } from './support/email-verification.provider';
import { MfaTokenStorage } from '../../../src/infrastructure/providers/mfa-token-storage';
import { ExternalLoginProvider } from '../../../src/infrastructure/providers/external-login.provider';
import { ReportDeliveryProvider } from './report-delivery.provider';
import { OrderProvider } from './order/order-provider.service';

export const dataProviders = [
    UsersProvider,
    ExportContactsProvider,
    GroupsProvider,
    AclProvider,
    ZendeskSsoProvider,
    AdminAppFileUploadProvider,
    SurveysProvider,
    SurveyPagesProvider,
    SurveysSettingProvider,
    FoldersProvider,
    SurveyListProvider,
    StyleTemplatesProvider,
    LimitsProvider,
    SurveyEditorProvider,
    TakeSurveyProvider,
    SurveyInvitationProvider,
    SurveyResponseProvider,
    AutocompleteListProvider,
    FileExtensionsProvider,
    SurveyExpressionsProvider,
    DashboardExpressionsProvider,
    SurveyMergeCodesProvider,
    LoginProvider,
    DashboardsProvider,
    ResponseExportSettingsProvider,
    ResetPasswordProvider,
    DatePipe,
    FileExportProvider,
    SurveyTemplatesProvider,
    MyAccountInfoProvider,
    EmailVerificationProvider,
    MfaTokenStorage,
    ExternalLoginProvider,
    ReportDeliveryProvider,
    OrderProvider
];

export {
    UsersProvider,
    ExportContactsProvider,
    GroupsProvider,
    AclProvider,
    ZendeskSsoProvider,
    SurveysProvider,
    SurveyPagesProvider,
    SurveysSettingProvider,
    SurveyInvitationProvider,
    SurveyResponseProvider,
    FoldersProvider,
    SurveyListProvider,
    StyleTemplatesProvider,
    LimitsProvider,
    SurveyEditorProvider,
    TakeSurveyProvider,
    AutocompleteListProvider,
    FileExtensionsProvider,
    SurveyExpressionsProvider,
    DashboardExpressionsProvider,
    SurveyMergeCodesProvider,
    LoginProvider,
    DashboardsProvider,
    ResponseExportSettingsProvider,
    ResetPasswordProvider,
    SurveyTemplatesProvider,
    MyAccountInfoProvider,
    EmailVerificationProvider,
    MfaTokenStorage,
    ExternalLoginProvider,
    ReportDeliveryProvider,
    OrderProvider
};
