export class StringPatterns {
    public static mergePattern = /([@]{2})(.[a-zA-Z0-9_]*)/g;
    public static localMergePattern = /([@]{2})(.[a-zA-Z0-9_]*)/;
    public static alphaNumericPattern = /^[0-9a-zA-Z]+$/;
    public static alphaPattern = /^[a-zA-Z]+$/;
    public static decimalNumericPattern = /^[-+]?[0-9]+\.[0-9]+$/;
    public static emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    public static numericPattern = /^[0-9]+$/;
    public static moneyPattern = /^\$?[0-9]+(\.[0-9][0-9])?$/;
    public static allNumbersPattern = /^-?\d*\.?\d*$/;
    public static USorCanadianPostalPattern = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
    public static northAmericaPhoneNumber = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    public static SSNpattern = /^\d{3}-\d{2}-\d{4}$/;
    public static upperCasePattern = /^[A-Z]+$/;
    public static lowerCasePattern = /^[a-z]+$/;
    public static URLpattern = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    public static fromAngleBracketsPattern = /<(.*)>/;
    public static internationalPhoneNumberPattern = /^\+[1-9]\d{1,14}$/;
    public static nanpPhoneNumberPattern = /^(\+?1\s?)?[\(\[.-]?\d{3}[\)\].-]?([\s.-]?\d){7}$/;
}
