import { MapperType } from '../../../../infrastructure/models/survey-settings.model';
import { ModelImplementor } from '../../../models/implementor.model';

export interface SurveyApplicationSettings {
    survey_preferences: SurveyApplicationSettingsPreferences;
    file_upload_item_settings: FileUploadApplicationSettings;
    advanced_survey_controls_settings: AdvancedSurveyControlsSettings;
}

export interface CaptchaSettings {
    invisible_recaptcha_secret: string;
    invisible_recaptcha_site_key: string;
    is_read_only: boolean;
    v2_recaptcha_secret: string;
    v2_recaptcha_site_key: string;
}


export interface AdvancedSurveyControlsSettings {
    allow_javascript_item: boolean;
    enable_javascript_item: boolean;
    enable_survey_wide_javascript: boolean;
    enable_survey_wide_css: boolean;
    allow_webhook_item: boolean;
    enable_webhook_item: boolean;
}

export interface AllowedFileType {
    extension: string;
    description: string;
}

export interface FileUploadApplicationSettings {
    is_enabled: boolean;
    allowed_file_types: AllowedFileType[];
    restrict_file_export: boolean;
}

export interface SurveyApplicationSettingsPreferences {
    default_security_type: string;
    default_survey_style_template: number;
    allow_edit_survey_style_template: boolean;
    default_question_editor_view: string;
    enable_custom_survey_urls: boolean;
    allow_editing_of_active_surveys: boolean;
    save_responses_when_navigating_back: boolean;
    make_next_finish_button_as_default_button: boolean;
    display_html_items_as_plain_text: boolean;
    include_incomplete_responses_to_total_amount: boolean;
    hide_sign_out: boolean;
}

export class AdvancedSurveyControlsSettingsModel extends ModelImplementor
    implements AdvancedSurveyControlsSettings {
    allow_javascript_item: boolean;
    enable_javascript_item: boolean;
    enable_survey_wide_css: boolean;
    enable_survey_wide_javascript: boolean;
    allow_webhook_item: boolean;
    enable_webhook_item: boolean;

    constructor(options: any = []) {
        const mapper: MapperType[] = [
            {
                in: 'allow_javascript_item;',
                out: 'allow_javascript_item',
                type: 'boolean'
            },
            {
                in: 'enable_javascript_item;',
                out: 'enable_javascript_item',
                type: 'boolean'
            },
            {
                in: 'enable_survey_wide_css;',
                out: 'enable_survey_wide_css',
                type: 'boolean'
            },
            {
                in: 'enable_survey_wide_javascript;',
                out: 'enable_survey_wide_javascript',
                type: 'boolean'
            },
            {
                in: 'allow_webhook_item;',
                out: 'allow_webhook_item',
                type: 'boolean'
            },
            {
                in: 'enable_webhook_item;',
                out: 'enable_webhook_item',
                type: 'boolean'
            }
        ];
        super(options, mapper);
    }
}

export class FileUploadApplicationSettingsModel extends ModelImplementor
    implements FileUploadApplicationSettings {
    allowed_file_types: AllowedFileType[];
    is_enabled: boolean;
    restrict_file_export: boolean;

    constructor(options: any = []) {
        const mapper: MapperType[] = [
            {
                in: 'allowed_file_types;',
                out: 'allowed_file_types',
                type: 'any'
            },
            { in: 'is_enabled;', out: 'is_enabled', type: 'boolean' },
            {
                in: 'restrict_file_export;',
                out: 'restrict_file_export',
                type: 'boolean'
            }
        ];
        super(options, mapper);
    }
}

export class AllowedFileTypeModel extends ModelImplementor
    implements AllowedFileType {
    extension: string;
    description: string;

    constructor(options: any = []) {
        const mapper: MapperType[] = [
            { in: 'extension;', out: 'extension', type: 'string' },
            { in: 'description;', out: 'description', type: 'string' }
        ];
        super(options, mapper);
    }
}

export class SurveyApplicationSettingsPreferencesModel extends ModelImplementor
    implements SurveyApplicationSettingsPreferences {
    default_security_type: string;
    default_survey_style_template: number;
    allow_edit_survey_style_template: boolean;
    default_question_editor_view: string;
    enable_custom_survey_urls: boolean;
    allow_editing_of_active_surveys: boolean;
    save_responses_when_navigating_back: boolean;
    make_next_finish_button_as_default_button: boolean;
    display_html_items_as_plain_text: boolean;
    include_incomplete_responses_to_total_amount: boolean;
    hide_sign_out: boolean;

    constructor(options: any = {}) {
        const mapper: MapperType[] = [
            {
                in: 'enable_custom_survey_urls;',
                out: 'enable_custom_survey_urls',
                type: 'boolean'
            },
            {
                in: 'include_incomplete_responses_to_total_amount;',
                out: 'include_incomplete_responses_to_total_amount',
                type: 'boolean'
            },
            {
                in: 'display_html_items_as_plain_text;',
                out: 'display_html_items_as_plain_text',
                type: 'boolean'
            },
            {
                in: 'make_next_finish_button_as_default_button;',
                out: 'make_next_finish_button_as_default_button',
                type: 'boolean'
            },
            {
                in: 'save_responses_when_navigating_back;',
                out: 'save_responses_when_navigating_back',
                type: 'boolean'
            },
            {
                in: 'allow_editing_of_active_surveys;',
                out: 'allow_editing_of_active_surveys',
                type: 'boolean'
            },
            {
                in: 'enable_custom_survey_urls;',
                out: 'enable_custom_survey_urls',
                type: 'boolean'
            },
            {
                in: 'allow_edit_survey_style_template;',
                out: 'allow_edit_survey_style_template',
                type: 'boolean'
            },
            {
                in: 'default_question_editor_view;',
                out: 'default_question_editor_view',
                type: 'string'
            },
            {
                in: 'default_survey_style_template;',
                out: 'default_survey_style_template',
                type: 'number'
            },
            {
                in: 'default_security_type;',
                out: 'default_security_type',
                type: 'string'
            },
            {
                in: 'hide_sign_out;',
                out: 'hide_sign_out',
                type: 'boolean'
            }
        ];
        super(options, mapper);
    }
}

export class SurveyApplicationSettingsModel extends ModelImplementor
    implements SurveyApplicationSettings {
    survey_preferences: SurveyApplicationSettingsPreferences;
    file_upload_item_settings: FileUploadApplicationSettings;
    advanced_survey_controls_settings: AdvancedSurveyControlsSettings;

    constructor(options: any = {}) {
        const mapper: MapperType[] = [
            {
                in: 'survey_preferences',
                out: 'survey_preferences',
                type: 'any'
            },
            {
                in: 'file_upload_item_settings',
                out: 'file_upload_item_settings',
                type: 'any'
            },
            {
                in: 'advanced_survey_controls_settings',
                out: 'advanced_survey_controls_settings',
                type: 'any'
            }
        ];
        super(options, mapper);
    }
}
