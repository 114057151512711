import { Action } from '@ngrx/store';

export const OPEN_CLOSE_SIDENAV = '[Layout] Open or Close Sidenav';
export const OPEN_SIDENAV = '[Layout] Open Sidenav';
export const CLOSE_SIDENAV = '[Layout] Close Sidenav';
export const SHOW_PROGRESS = '[Layout] Show progress';
export const HIDE_PROGRESS = '[Layout] Hide progress';

export class OpenCloseSidenavAction implements Action {
    readonly type = OPEN_CLOSE_SIDENAV;

    constructor() {}
}

export class ShowProgressAction implements Action {
    readonly type = SHOW_PROGRESS;

    constructor() {}
}

export class HideProgressAction implements Action {
    readonly type = HIDE_PROGRESS;

    constructor() {}
}

export type Actions =
    | OpenCloseSidenavAction
    | ShowProgressAction
    | HideProgressAction;
