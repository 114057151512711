import {
    ModelImplementor,
    ModelPageListImplementor
} from '../../../app-admin/models/implementor.model';
import { MapperType } from '../survey-settings.model';

export interface SurveyResponseListItem {
    id: string;
    numericId: number;
    respondent: string;
    started: Date;
    ended?: Date;
    lastEdit: Date;
    status: string;
    is_test: boolean;
    language: string;
}

export interface SurveyResponsesTimeLine {
    group_by: string;
    items: Array<SurveyResponsesTimeLineItem>;
    survey_id: number;
    summary:  SummaryResponse;
}

export interface SummaryResponse {
    complete_response_count: number;
    incomplete_response_count: number;
    test_response_count: number;
}

export interface SurveyResponsesTimeLineItem {
    date: string;
    response_count: number;
}


export class SurveyBulkDeleteModel {
    survey_id: string;
    response_ids: string[];
}

export class SurveyResponseModel extends ModelImplementor
    implements SurveyResponseListItem {
    id: string;
    numericId: number;
    lastEdit: Date;
    respondent: string;
    started: Date;
    ended?: Date;
    status: string;
    is_test: boolean;
    contactId?: string;
    totalTimeInSeconds?: number;
    ipAddress?: string;
    score?: number;
    pages?: any;
    invitee?: string;
    language: string;

    constructor(options: any) {
        const mapper: MapperType[] = [
            { in: 'id', out: 'id', type: 'string' },
            { in: 'numericId', out: 'numeric_id', type: 'number' },
            { in: 'respondent', out: 'contact_id', type: 'any' },
            { in: 'started', out: 'started', type: 'date' },
            { in: 'ended', out: 'ended', type: 'date' },
            { in: 'lastEdit', out: 'last_edit', type: 'date' },
            { in: 'status', out: 'status', type: 'any' },
            { in: 'is_test', out: 'is_test', type: 'boolean' },
            { in: 'import_batch_id', out: 'import_batch_id', type: 'any' },
            { in: 'totalTimeInSeconds', out: 'total_time_in_seconds', type: 'number' },
            { in: 'ipAddress', out: 'ip_address', type: 'string' },
            { in: 'score', out: 'score', type: 'number' },
            { in: 'pages', out: 'pages', type: 'any' },
            { in: 'invitee', out: 'invitee', type: 'string' },
            { in: 'language', out: 'language', type: 'string' }
        ];
        super(options, mapper);
    }
}

export class SurveyResponseListModel extends ModelPageListImplementor<
    SurveyResponseListItem
> {
    constructor(options: any) {
        super(options, SurveyResponseModel);
    }
}

export interface ResponseContactInfo {
    id: string;
    email?: string;
    profile_properties?: ResponseContactProfileInfo[];
}

export interface ResponseContactProfileInfo {
    property_name: string;
    value: string;
}

export class ResponseWebhookCall {
    id: string;
    timestamp: Date;
    survey_id: number;
    survey_response_id: string;
    item_id: number;
    url: string;
    payload: string;
    response_status_code: number;
    response_headers: string;
    response_body: string;
    error_message: string;
}
