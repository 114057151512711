import { PagingInfo } from '../../../infrastructure/models';

export class SurveyListGetData {
    search_text: string;
    folder_id: number;
    paging_info: PagingInfo;
    status_filter: string;
    sort_criteria?: {
        sort_by: string,
        sort_order: string
    }
}
