import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AppState } from './reducers';
import { layout } from './actions';
import { getSidenavStatus } from './selectors';

@Injectable()
export class LayoutStore {
    constructor(private store: Store<AppState>) {}

    get sidenavStatus(): Observable<boolean> {
        return this.store.select(getSidenavStatus);
    }

    doOpenCloseSidenav(): void {
        this.store.dispatch(new layout.OpenCloseSidenavAction());
    }
}
