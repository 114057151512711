import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PagedList } from '../../../infrastructure/models';
import { SurveyListGetData, SurveyListItem } from '../../models';
import { ResourcesConsts } from '../../consts/resources.consts';
import { CustomURLEncoder } from '../../../infrastructure/helpers/urlEncode.helper';

@Injectable()
export class SurveyListProvider {
    url: string = ResourcesConsts.SURVEY_LIST;

    constructor(private $http: HttpClient) {}

    loadSurveys(
        getData: SurveyListGetData
    ): Observable<PagedList<SurveyListItem>> {
        const params = new HttpParams({encoder: new CustomURLEncoder() })
            .set(
                'folder_id',
                getData.folder_id ? getData.folder_id.toString() : ''
            )
            .set('search_text', getData.search_text)
            .set('page_num', getData.paging_info.page_num.toString())
            .set('page_size', getData.paging_info.page_size.toString())
            .set('status', getData.status_filter)
            .set('sort_by', getData.sort_criteria?.sort_by)
            .set('sort_order', getData.sort_criteria?.sort_order);

        return this.$http
            .get<PagedList<SurveyListItem>>(this.url, { params: params });
    }

    loadFavoriteSurveys(
        getData: SurveyListGetData
    ): Observable<PagedList<SurveyListItem>> {
        const params = new HttpParams({encoder: new CustomURLEncoder() })
            .set('folder_id', '')
            .set('search_text', '')
            .set('status', '')
            .set('page_num', getData.paging_info.page_num.toString())
            .set('page_size', getData.paging_info.page_size.toString())
            .set('is_favorite', 'true')
            .set('status', 'Published,Draft');

        return this.$http
            .get<PagedList<SurveyListItem>>(this.url, { params: params });
    }
}
