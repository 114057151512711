import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, UrlSerializer } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ReactiveFormsModule } from '@angular/forms';

import * as infra from '../../infrastructure';

import { routes } from './routes.module';

import { AppComponent } from './app.component';

import { reducers } from '../store/reducers';
import { effects } from '../store/effects';
import { dataProviders } from '../providers';
import { guards } from '../guards';
import { stores } from '../store';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { SharedModule } from '../../shared/modules/shared.module';
import { services } from '../services';
import { TakeSurveyUserIdentityModule } from '../identity/take-survey-user-identity.module';
import { TakeSurveyPagesModule } from '../pages/take-survey-pages.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ReportRootComponent } from '../reports/report-root/report-root.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OldSurveyRedirectComponent } from '../surveys/oldSurveyRedirect/old-survey-redirect.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { version } from '../../environments/version';
import { CustomMaterialModule } from '../../shared/modules/material-components.module';
import { LowerCaseUrlSerializer } from '../../infrastructure/helpers/urlSerializer';
import { transformToCloudfrontUrl } from '../../infrastructure/helpers/cloudfront.helper';
import { EventLogComponent } from '../../shared/components/event-log/event-log.component';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(
        http,
        transformToCloudfrontUrl('assets/i18n/'),
        '.json?v=' + version
    );
}

@NgModule({
    declarations: [AppComponent, ReportRootComponent, OldSurveyRedirectComponent, EventLogComponent],
    imports: [
        // system
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ReactiveFormsModule,
        RouterModule.forRoot(routes),
        StoreModule.forRoot(reducers),
        EffectsModule.forRoot(effects),
        NgxPermissionsModule.forRoot(),
        // custom
        CustomMaterialModule,
        TakeSurveyUserIdentityModule,
        TakeSurveyPagesModule,
        // translation
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        SharedModule
    ],
    providers: [...guards, ...stores, ...dataProviders, ...infra.providers, ...services,
        {
            provide: UrlSerializer,
            useClass: LowerCaseUrlSerializer
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
