import { Injectable } from '@angular/core';

declare let ga: Function;
@Injectable()
export class GoogleAnalyticsService {
    constructor() {}

    public static isGoogleAnalyticsLoaded() {
        try {
            return  ga !== undefined;
        } catch (e) {
            return false;
        }
    }

    public eventEmitterRoute(id: string, url: string) {
        const tracker = `tracker${id}`.replace(/-/g, '');
        this.initConfig(id, tracker);
        ga(`${tracker}.set`, 'page', url);
        this.sendPageView(tracker);
    }

    private initConfig(id: string, tracker: string) {
        ga('create', id, 'auto', tracker);
        this.sendPageView(tracker);
    }

    private sendPageView(tracker: string) {
        ga(tracker ? `${tracker}.send` : 'send', 'pageview');
    }
}
