import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { Identity, Signin } from '../../../infrastructure/models';
import { IdentityStore } from '../../../infrastructure/store';

@Component({
    selector: 'cb-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
    public identity: Observable<Identity>;
    public isTakeSurvey = true;

    constructor(
        private identityStore: IdentityStore
    ) {
        this.identity = this.identityStore.identity;
    }

    ngOnInit() {
        this.prepareForRedirect();
    }

    prepareForRedirect() {
        this.identity.subscribe(i => {
            if (i && i.access_token) {
                this.identityStore.goToRedirectUri(i, true);
            }
        });
    }

    public onLogin(user: Signin) {
        this.identityStore.doSignIn(user);
    }
}
