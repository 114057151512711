import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import * as _ from 'lodash';
import { SurveyPage } from '../../infrastructure/models/survey-page.model';
import {
    getPostCurrentPageErrors,
    ServerErrorItemType
} from '../../infrastructure/errors/post-response-handler.error';
import { ToasterService } from '../../infrastructure/services';

@Injectable()
export class TakeSurveyErrorsService {

    constructor(
        private toasterService: ToasterService,
        private errorHandler: ErrorHandler
    ) {}

    handlePostPageAnswersError(error, currentPage: SurveyPage) {
        const isHttpError = error instanceof HttpErrorResponse;
        if (!isHttpError) {
            this.errorHandler.handleError(error);
            return;
        }

        const validationErrors = isHttpError && error.status === 400
            ? getPostCurrentPageErrors(error.error)
            : [];

        if (!validationErrors.length) {
            return;
        }

        const groupedErrors = _.groupBy(validationErrors, x => x.errorItem.itemId);

        for (const key in groupedErrors) {
            if (groupedErrors.hasOwnProperty(key)) {
                const item = currentPage.items.find(i => i.id === +key);
                item.server_error_messages = [];
                groupedErrors[key].forEach((err: any) => {
                    switch (err.errorItem.type) {
                        case ServerErrorItemType.SurveyItem:
                            item.server_error_messages.push(err.error_text);
                            break;
                        case ServerErrorItemType.MatrixColumn:
                            item.server_error_messages.push({
                                columnId: err.errorItem.columnId,
                                errorText: err.error_text
                            });
                            break;
                        case ServerErrorItemType.MatrixSubItem:
                            item.server_error_messages.push({
                                subItemId: err.errorItem.subItemId,
                                errorText: err.error_text
                            });
                            break;
                        default:
                            this.toasterService.showError(error.message);
                            break;
                    }
                });
            }
        }
    }

    clearServerErrorMessages(currentPage: SurveyPage) {
        currentPage.items.forEach(item => {
            if (item.server_error_messages) {
                item.server_error_messages = [];
            }
        });
    }

    handleError(error) {
        this.errorHandler.handleError(error);
    }
}
