import { EventEmitter, Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { CustomMatSnackBarComponent } from '../../shared/components/custom-mat-snack-bar/custom-mat-snack-bar.component';
import * as _ from 'lodash';

@Injectable()
export class ToasterService {
    private readonly errorConfig: MatSnackBarConfig;
    private readonly infoConfig: MatSnackBarConfig;
    private readonly warningConfig: MatSnackBarConfig;
    private snackBarRef: MatSnackBarRef<CustomMatSnackBarComponent>;
    private isInstanceVisible: boolean;
    private messageQueue: Array<any> = Array<any>();
    private contentEventEmitter: EventEmitter<any> = new EventEmitter<any>()

    constructor(
        private snackBar: MatSnackBar,
        private translateService: TranslateService
    ) {
        const config = new MatSnackBarConfig();
        config.horizontalPosition = 'center';
        config.verticalPosition = 'top';
        config.duration = 0;
        this.errorConfig = Object.assign({}, config, {
            panelClass: ['error']
        });
        this.warningConfig = Object.assign({}, config, {
            panelClass: ['warning'],
            duration: 3000
        });
        this.infoConfig = Object.assign({}, config, {
            panelClass: ['info'],
            duration: 3000
        });
    }

    showError(error, hideCopyBtn = false) : EventEmitter<any> {
        this.show(error.message, null, this.errorConfig, false, error.fullError, hideCopyBtn);
        return this.contentEventEmitter;
    }

    showWarning(msg: string, localizedString = false, duration?: number) {
        const config = Object.assign({}, this.warningConfig);
        config.duration = duration ?? config.duration;

        if (localizedString) {
            this.translateSnackbar(msg, config);
        } else {
            this.show(msg, null, config, true, '', true);
        }
    }

    showInfo(msg: string, localizedString = false, duration?: number) {
        const config = Object.assign({}, this.infoConfig);
        config.duration = duration ?? config.duration;

        if (localizedString) {
            this.translateSnackbar(msg, config);
        } else {
            this.show(msg, null, config, true, '', true);
        }
    }

    private translateSnackbar(text, config, action = null) {
        if (text) {
            this.translateService.get(text).subscribe((data: string) => {
                setTimeout(() => {
                    this.show(data, action, config, true, '', true);
                });
            });
        }
    }

   show(
       message: string,
       action?: string,
       config?: MatSnackBarConfig,
       hideCloseBtn?: boolean,
       fullErrorMessage?: string,
       hideCopyBtn?: boolean
   ): void {
     if (!config) {
         config = new MatSnackBarConfig();
         config.duration = 0;
         config.verticalPosition = 'bottom';
       config.horizontalPosition = 'end';
     }

       const sbMessage = {
           message,
           action,
           config,
           hideCloseBtn,
           fullErrorMessage,
           hideCopyBtn
       };
       if (this.messageQueue.length) {
           this.messageQueue.forEach(
               item => {
                   if (!_.isEqual(item, sbMessage)) {
                       this.messageQueue.push(sbMessage);
                   }
               }
           );
       } else {
           this.messageQueue.push(sbMessage);
       }

       if (!this.isInstanceVisible) {
           this.showNext();
       }
   }

    private showNext() {
        if (this.messageQueue.length === 0) {
            return;
        }

        const { message, config, action, hideCloseBtn, fullErrorMessage, hideCopyBtn } = this.messageQueue.shift();
        setTimeout(() => {
            this.isInstanceVisible = true;
            this.snackBarRef = this.snackBar.openFromComponent(CustomMatSnackBarComponent, {
                ...config,
                ...action,
                data: {
                    text: message,
                    hideCloseBtn,
                    fullErrorMessage,
                    hideCopyBtn
                }
            });
            this.snackBarRef.afterDismissed().subscribe(() => {
                this.isInstanceVisible = false;
                this.showNext();
            });
            this.snackBarRef.instance.contentEventEmitter.subscribe((data) => {
                this.contentEventEmitter.emit(data);
            });
        }, 0);
    }
}
