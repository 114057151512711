import { environment } from '../../environments/environment';

function getAdminCloudfrontUrl() {
    return window['__env'] && window['__env'].cloudFrontAdmin;
}

function getTakeSurveyCloudfrontUrl() {
    return window['__env'] && window['__env'].cloudFrontTakeSurvey;
}

export function transformToCloudfrontUrl(url: string) {
    const currentEnvUrl = environment.isAdminSite ? getAdminCloudfrontUrl() : getTakeSurveyCloudfrontUrl();
    const isTranslationsFile = url.indexOf('i18n') > -1;
    if (environment.name !== 'server' && currentEnvUrl) {
        return `${currentEnvUrl}/${url}`;
    } else {
        return url;
    }
}
