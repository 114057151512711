<h2 mat-dialog-title="">{{ title }}</h2>
<span mat-dialog-content style="white-space: pre">{{ message }}</span>
<span mat-dialog-content>{{ 'SHARED.NO-UNDONE-WARNING' | translate }}</span>
<div class="cb-confirm-content">
    <form [formGroup]="form" novalidate name="confirmationForm">
        <mat-form-field class="full-width-input">
            <input
                matInput
                placeholder="{{ 'DIALOG.DELETE-CONFIRM' | translate }}"
                formControlName="delete_confirm"
            />
            <mat-error>
                {{ 'DIALOG.TYPE-DELETE' | translate }} {{ objectName }}
            </mat-error>
        </mat-form-field>
        <mat-checkbox formControlName="checkboxRule" *ngIf="checkboxRule">
            {{checkboxRuleText}}                      
        </mat-checkbox>
        <div mat-dialog-actions class="cb-right">
            <button
                type="button"
                mat-raised-button
                color="warn"
                (click)="closeDialog()"
                [disabled]="!form.valid"
            >
                {{ 'SHARED.DELETE' | translate }}
            </button>
            <button type="button" mat-button (click)="dialogRef.close()">
                {{ 'SHARED.CANCEL' | translate }}
            </button>
        </div>
    </form>
</div>
