import { Injectable } from '@angular/core';
import flagsmith from 'flagsmith';
import { environment } from '../../environments/environment';

if (environment.featureFlagEnvironmentID) {
    flagsmith.init({
        environmentID: environment.featureFlagEnvironmentID,
        cacheFlags: environment.featureFlagEnableCache,
        enableAnalytics: false,
        cacheOptions: {
            skipAPI: false,
            ttl: 24 * 60 * 60 * 1000 // 1 day in ms
        }
    });
    // flagsmith.startListening(60000); // Poll the api for changes every x milliseconds
}

// Example of using https://www.notion.so/Feature-flag-7cb3b7f9ed2f4a029f6173ae53f0adc5
@Injectable()
export class FeatureFlagService {
    isEnabled = false;
    constructor() {
        this.isEnabled = !!environment.featureFlagEnvironmentID;
    }

    isFeatureOn(featureName: string): boolean {
        if (!this.isEnabled) {
            return true;
        }

        return flagsmith.hasFeature(featureName);
    }
}
