import { ItemBase } from './../itemBase';

export class ScoringMessageItem extends ItemBase {
    messages: TextMessage[];
    score_scope_page_id: number;
    html_class: string;
    item_position: string;
    font_color: string;
    font_size: string;
    pageTitles: any[];
    softRequiredEnabled?: boolean;
}

export interface TextMessage {
    low_score: number;
    high_score: number;
    text: string;
}
