<div [innerHTML]="data.text | keepHtml : tagWhiteList"></div>

<button
    *ngIf="!data.hideCopyBtn"
    mat-raised-button
    (click)="copyToClipboard()">
    {{ 'SHARED.COPY-CLIPBOARD' | translate }}
</button>

<button
    *ngIf="!data.hideCloseBtn"
    class="mat-icon-button cb-icon-button close-btn"
    (click)="dismiss()">
    <cb-svg-icon
        [name]="'close'"
    ></cb-svg-icon>
</button>
