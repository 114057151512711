import { ErrorHandler, Injectable, Injector } from '@angular/core';

import { ErrorStore } from '../store';
import { ClientUnexpectedError, ApplicationError } from '../models';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private injector: Injector) {}

    handleError(error) {
        const errorStore = this.injector.get(ErrorStore);

        let globalError: ApplicationError;
        if (error instanceof Error) {
            globalError = new ClientUnexpectedError(error.message, error.stack);
            errorStore.doLogError(globalError);
        }
    }
}
