import { Component, Inject, NgZone, HostListener, ElementRef, Renderer2, EventEmitter, ViewChild, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { ClipboardService } from 'ngx-clipboard';

@Component({
    selector: 'cb-custom-mat-snack-bar',
    templateUrl: 'custom-mat-snack-bar.component.html',
    styleUrls: ['custom-mat-snack-bar.component.scss']
})

export class CustomMatSnackBarComponent {
    public contentEventEmitter = new EventEmitter<any>();
    public tagWhiteList = {
        a: ['data-eventid']
     };
    
    @HostListener('document:click', ['$event'])

    clickout(event) {
        if (this.data.hideCopyBtn && !this.eRef.nativeElement.contains(event.target)) {
            this.dismiss();
        }
    }
    constructor(
        private eRef: ElementRef,
        private renderer: Renderer2,
        public snackBarRef: MatSnackBarRef<CustomMatSnackBarComponent>,
        private readonly zone: NgZone,
        private _clipboardService: ClipboardService,
        @Inject(MAT_SNACK_BAR_DATA) public data: any) {

            renderer.listen(eRef.nativeElement, 'click', (event) => {
                var id = event.target.getAttribute('data-eventid');       
                if (id) {
                    this.contentEventEmitter.emit(id);
                }
                
                return false;
            });
    }

    dismiss() {
        this.zone.run(() => {
                this.snackBarRef.dismiss();
            }
        );
    }

    copyToClipboard() {
        this._clipboardService.copyFromContent(this.data.fullErrorMessage);
    }
}
