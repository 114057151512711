<cb-spinner [show]="isLoading"></cb-spinner>
<ng-container *ngIf="!isLoading">
    <div
        class="survey-response-details__page"
        *ngFor="let page of _pages"
    >
        <span class="survey-response-details__page__title">{{
            getPageLabel(page)
            }}</span>
        <div
            class="survey-response-details__item"
            *ngFor="let item of page.items; let i = index"
        >
            <ng-container *ngIf="item.enabled || item.answer">
                <span
                    class="survey-response-details__question"
                    *ngIf="item.question_text && showItemNumeration"
                >
                    <span class="survey-response-details__numeration"
                    >{{ item.position }} - </span
                    >{{ stripHtml(item.question_text) }}
                </span>
                <span
                    class="survey-response-details__question"
                    *ngIf="!item.question_text && showItemNumeration"
                >
                    <span class="survey-response-details__numeration">
                        {{ item.position }}
                    </span>
                </span>
                <span
                    class="survey-response-details__question"
                    *ngIf="!showItemNumeration"
                >
                    {{ item.question_text ? stripHtml(item.question_text) : ''}}
                </span>

                <ng-container *ngIf="item.answer">
                    <div
                        class="survey-response-details__answer"
                        *ngIf="
                            item.item_type === questionType.SINGLE_LINE_TEXT ||
                            item.item_type === questionType.MULTI_LINE_TEXT ||
                            item.item_type === questionType.HIDDEN_ITEM
                        "
                    >
                        <span
                            *ngIf="getTextAnswer(item)"
                            [innerHTML]="getTextAnswer(item) | keepHtml"
                        ></span>
                        <!--                <i *ngIf="!getTextAnswer(item)">{{-->
                        <!--                    'SURVEY-RESPONSES.RESPONSE-DETAILS.NOT-SPECIFIED'-->
                        <!--                        | translate-->
                        <!--                }}</i>-->
                    </div>
                    <div
                        class="survey-response-details__answer"
                        *ngIf="item.item_type === questionType.CHECKBOXES"
                    >
                        <span [innerHTML]="getCheckboxesAnswer(item, item.answer) | keepHtml"></span>
                    </div>
                    <div
                        class="survey-response-details__answer"
                        *ngIf="
                            item.item_type === questionType.RANKORDER &&
                            item.option_type !== 'Image'
                        "
                    >
                        <span
                            [innerHTML]="getRankOrderTextAnswer(item) | keepHtml"
                        ></span>
                    </div>
                    <div
                        class="survey-response-details__answer"
                        *ngIf="
                            item.item_type === questionType.RANKORDER &&
                            item.option_type === 'Image'"
                    >
                        <img
                            class="survey-response-details__ro-image"
                            [src]="image"
                            *ngFor="
                                let image of getImageAnswer(item, page)
                            "
                        />
                    </div>
                    <div
                        class="survey-response-details__answer"
                        *ngIf="
                            item.item_type === questionType.IMAGE_CHOICE
                        "
                    >
                        <img
                            class="survey-response-details__ro-image"
                            [src]="image"
                            *ngFor="
                                let image of getImageAnswer(item, page)
                            "
                        />
                    </div>
                    <div
                        class="survey-response-details__answer"
                        *ngIf="
                            item.item_type === questionType.RADIOBUTTONS ||
                            item.item_type === questionType.DROPDOWNLIST ||
                            item.item_type === questionType.NET_PROMOTER_SCORE ||
                            item.item_type === questionType.CUSTOM_SOURCE_DROPDOWNLIST ||
                            item.item_type === questionType.RATINGSCALE ||
                            item.item_type === questionType.RATING "
                    >
                        <span [innerHtml]="getSingleChoiceValueText(item, item.answer) | keepHtml"></span>
                    </div>
                    <div
                        class="survey-response-details__answer"
                        *ngIf="item.item_type === questionType.DRILL_DOWN"
                    >
                        <span [innerHTML]="getDrillDownAnswer(item, item.answer) | keepHtml"></span>
                    </div>
                    <div
                        class="survey-response-details__answer"
                        *ngIf="item.item_type === questionType.SLIDER"
                    >
                        <span *ngIf="item.value_type !== 'Image' || !getSingleChoiceValueImage(item)" [innerHtml]="getSliderAnswer(item) | keepHtml"></span>
                        <img
                            *ngIf="item.value_type === 'Image' && getSingleChoiceValueImage(item)"
                                class="survey-response-details__answer--image"
                                [src]="getSingleChoiceValueImage(item)"
                        />
                    </div>

                    <div
                        class="survey-response-details__answer"
                        *ngIf="item.item_type === questionType.MAXDIFF"
                    >
                        <table
                            class="survey-response-details__max-diff-set"
                            *ngFor="let set of item.answer.sets"
                        >
                            <caption>
                                <span
                                >set {{ set.set_number + 1 }} of
                                    {{ item.number_sets }}</span
                                >
                            </caption>
                            <tr>
                                <th *ngFor="let label of getMaxDiffLabels(item)">
                                    {{ label }}
                                </th>
                            </tr>
                            <tr
                                *ngFor="
                                    let choice of getMaxDiffChoices(
                                        item,
                                        set.set_number
                                    );
                                    let choiceIndex = index
                                "
                            >
                                <td
                                    *ngFor="
                                        let label of getMaxDiffChoiceRow(
                                            choice,
                                            set,
                                            item
                                        )
                                    "
                                    [innerHtml]="label"
                                >
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div
                        class="survey-response-details__answer"
                        *ngIf="item.item_type === questionType.FILE_UPLOAD"
                    >
                        <div *ngIf="item.answer?.file?.id">
                            <span>{{ getUploadedFileCaption(item) }}</span>
                            <a
                                class="survey-response-details__link"
                                [href]="getFilePath(item) | secure | async"
                                [download]="item.answer?.file?.file_name"
                                target="_blank"
                                *ngIf="showFileUploads && item.answer?.file?.scan_result !== 'VirusDetected'"
                            >{{
                                'SURVEY-RESPONSES.RESPONSE-DETAILS.DOWNLOAD-FILE'
                                    | translate
                                }}</a
                            >
                            <div
                                *ngIf="showFileUploads && !isPrintMode && item.answer?.file?.scan_result === 'VirusDetected'"
                                class="warning-message">
                                <span>{{ 'SURVEY-RESPONSES.RESPONSE-DETAILS.INFECTED-FILE' | translate }}</span>
                            </div>
                        </div>
                    </div>
                    <div
                        class="survey-response-details__answer"
                        *ngIf="item.item_type === questionType.SIGNATURE"
                    >
                        <div *ngIf="item.answer?.file?.id">
                            <span>{{ getUploadedFileCaption(item) }}</span>
                            <img
                                class="survey-response-details__signature"
                                [src]="getFilePath(item) | secure | async | notNull"
                            />
                            <!--                    <a class="survey-response-details__link" [href]="getFilePath(item)">{{'SURVEY-RESPONSES.RESPONSE-DETAILS.DOWNLOAD-FILE' | translate}}</a>-->
                        </div>
                    </div>
                </ng-container>
                <div
                    class="survey-response-details__answer"
                    *ngIf="item.item_type === questionType.MATRIX"
                >
                    <div *ngIf="item.subitems?.length" class="survey-response-details__matrix_wrapper">
                        <table border="1" class="survey-response-details__matrix">
                            <tr class="survey-response-details__matrix__headers">
                                <th
                                    class="survey-response-details__matrix__header"
                                    *ngFor="
                                        let column of getSortedColumns(item.columns)
                                    "
                                >
                                    <span
                                        [innerHtml]="
                                            column.prototype_item?.question_text | keepHtml
                                        "
                                    ></span>&emsp;
                                </th>
                            </tr>
                            <tr *ngFor="let row of getSortedRows(item.rows); let i = index">
                                <td
                                    *ngFor="
                                        let cell of getElementsForRow(
                                            item,
                                            row.position
                                        )
                                    "
                                    [innerHtml]='getMatrixRowContent(cell) | keepHtml'
                                >
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div
                    class="survey-response-details__answer"
                    *ngIf="item.item_type === questionType.CONTACT_FORM"
                >
                    <div *ngIf="item.subitems?.length" class="survey-response-details__matrix_wrapper">
                        <table border="1" class="survey-response-details__matrix">
                            <tr *ngFor="let field of getSortedContactFormFields(item); let i = index">
                                <td
                                     [innerHtml]='field.question?.question_text | keepHtml'
                                >
                                </td>
                                <td
                                    [innerHtml]='getContactFormFieldAnswerText(item, field) | keepHtml'
                                >
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div
                    class="survey-response-details__answer"
                    *ngIf="
                        item.item_type === surveyDisplayType.MESSAGE &&
                        showDisplayMessages
                    "
                >
                    <span *ngIf="item.text" [innerHTML]="item.text | keepHtml"></span>
                </div>
                <div
                    class="survey-response-details__answer"
                    *ngIf="
                        item.item_type === surveyDisplayType.SCORING_MESSAGE &&
                        showDisplayMessages
                    "
                >
                    <cb-scoring-message-item-preview
                        [pages]="_pages"
                        [isTakeSurvey]="true"
                        [displayItem]="item"
                    ></cb-scoring-message-item-preview>
                </div>
                <div
                    class="survey-response-details__answer"
                    *ngIf="
                        item.item_type === surveyDisplayType.IMAGE &&
                        showDisplayMessages
                    "
                >
                    <img
                        class="survey-response-details__answer--image"
                        [src]="item.image?.file_url"
                        *ngIf="item.image"
                    />
                </div>
            </ng-container>

        </div>
    </div>
</ng-container>
