export class MfaTokenStorage {
    public getMfaEmailToken(contactId: string, accountName: string) {
        return localStorage.getItem(this.getMfaEmailTokenLocalStorageKey(contactId, accountName))
    }

    public setMfaEmailToken(contactId: string, accountName: string, token: string) {
        localStorage.setItem(this.getMfaEmailTokenLocalStorageKey(contactId, accountName), token)
    }

    private getMfaEmailTokenLocalStorageKey(contactId: string, accountName: string) {
        return `mfa-email-token-${accountName}-${contactId}`;
    }
}