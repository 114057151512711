import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
    CompanyProfile,
    CompanyProfileModel,
    createCompanyProfiles,
    createInvitationPanelResults,
    InvitationContactListModel,
    InvitationGroupListModel,
    InvitationMessage,
    InvitationMessageModel,
    InvitationPanelListModel,
    InvitationPanelCreateResultModel,
    InvitationRecipientListModel,
    InvitationReminder,
    InvitationReminderModel,
    InvitationScheduleListModel,
    InvitationSummaryModel,
    SurveyInvitation,
    SurveyInvitationListModel,
    SurveyInvitationModel,
    SmsMessageLimitsModel
} from '../../app-admin/models/survey-invitation.model';
import { String } from 'typescript-string-operations-ng4';
import { ResourcesConsts } from '../../app-admin/consts/resources.consts';
import { PageRequestOptions } from '../models';
import { addQueryString, createPageQuery } from '../helpers/string.helpers';
import { FileExportProvider } from './file-export.provider';

@Injectable()
export class SurveyInvitationProvider {
    constructor(private $http: HttpClient, private fileExportProvider: FileExportProvider) {}

    getInvitationsBySurveyId(
        surveyId: string,
        params
    ): Observable<SurveyInvitationListModel> {
        const url: string =
            String.Format(ResourcesConsts.SURVEY_INVITATION_BASICS, surveyId);
        return this.$http.get(url, { params }).pipe(
            map((data: any) => new SurveyInvitationListModel(data))
        );
    }

    getInvitationsSummaryBySurveyId(
        surveyId: string
    ): Observable<InvitationSummaryModel> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_INVITATION_SUMMARY,
            surveyId
        );
        return this.$http.get(url).pipe(
            map((data: any) => new InvitationSummaryModel(data))
        );
    }

    deleteInvitations(
        surveyId: string,
        invitationIds: string[]
    ): Observable<void> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_INVITATION_BASICS,
            surveyId
        );
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            body: invitationIds
        };
        return this.$http.delete(url, options).pipe(
            map(() => {
            })
        );
    }

    getInvitationPanels(
        surveyId: string,
        invitationId: number,
        options: PageRequestOptions
    ): Observable<InvitationPanelListModel> {
        const url: string =
            String.Format(
                ResourcesConsts.SURVEY_INVITATION_PANELS,
                surveyId,
                invitationId
            ) + createPageQuery(options);
        return this.$http.get(url).pipe(
            map((data: any) => new InvitationPanelListModel(data))
        );
    }

    loadContactsSubjPage(
        surveyId: string,
        invitationId: string,
        options: PageRequestOptions
    ): Observable<InvitationContactListModel> {
        const url: string =
            String.Format(
                ResourcesConsts.SURVEY_INVITATION_PANELS_CONTACTS,
                surveyId,
                invitationId
            ) + createPageQuery(options) + '&include_details=true';
        return this.$http.get(url).pipe(
            map((data: any) => new InvitationContactListModel(data))
        );
    }

    loadGroupsSubjPage(
        surveyId: string,
        invitationId: string,
        options: PageRequestOptions
    ): Observable<InvitationGroupListModel> {
        const url: string =
            String.Format(
                ResourcesConsts.SURVEY_INVITATION_PANELS_GROUPS,
                surveyId,
                invitationId
            ) + createPageQuery(options);
        return this.$http.get(url).pipe(
            map((data: any) => new InvitationGroupListModel(data))
        );
    }

    getInvitationRecipients(
        surveyId: string,
        invitationId: number,
        options: PageRequestOptions
    ): Observable<InvitationRecipientListModel> {
        const url: string =
            String.Format(
                ResourcesConsts.SURVEY_INVITATION_RECIPIENTS,
                surveyId,
                invitationId
            ) + createPageQuery(options);
        return this.$http.get(url).pipe(
            map((data: any) => new InvitationRecipientListModel(data))
        );
    }

    getInvitationRecipientLink(
        surveyId: string,
        invitationId: number,
        recipientId: number
    ): Observable<string> {
        const url: string =
            String.Format(
                ResourcesConsts.SURVEY_INVITATION_RECIPIENT_LINK,
                surveyId,
                invitationId,
                recipientId
            );
        return this.$http.get(url).pipe(
            map((data: any) => data)
        );
    }

    resendInvitationToRecipient(
        surveyId: string,
        invitationId: number,
        recipientId: number
    ): Observable<void> {
        const url: string =
            String.Format(
                ResourcesConsts.SURVEY_INVITATION_RECIPIENT_RESEND_INVITATION,
                surveyId,
                invitationId,
                recipientId
            );
            return this.$http.post(url, {}).pipe(
                map((data: any) => data)
            );
    }

    resendReminderToRecipient(
        surveyId: string,
        invitationId: number,
        recipientId: number
    ): Observable<void> {
        const url: string =
            String.Format(
                ResourcesConsts.SURVEY_INVITATION_RECIPIENT_RESEND_REMINDER,
                surveyId,
                invitationId,
                recipientId
            );
            return this.$http.post(url, {}).pipe(
                map((data: any) => data)
            );
    }

    getInvitationSchedules(
        surveyId: string,
        invitationId: number,
        options: PageRequestOptions
    ): Observable<InvitationScheduleListModel> {
        const url: string =
            String.Format(
                ResourcesConsts.SURVEY_INVITATION_SCHEDULES,
                surveyId,
                invitationId
            ) + createPageQuery(options);
        return this.$http.get(url).pipe(
            map((data: any) => new InvitationScheduleListModel(data))
        );
    }

    setScheduledData(
        surveyId: string,
        invitationId: number,
        data: { scheduled_date: string }
    ): Observable<void> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_INVITATION_SCHEDULES,
            surveyId,
            invitationId
        );
        return this.$http.post(url, data).pipe(
            map(() => {
            })
        );
    }

    markInvitationOptedOut(
        surveyId: string,
        invitationId: number,
        recipientIds: number[]
    ): Observable<any> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_INVITATION_RECIPIENTS_OPT_OUT,
            surveyId,
            invitationId
        );
        return this.$http.post(url, recipientIds).pipe(
            map((data: any) => data)
        );
    }

    getDetailsSummary(
        surveyId: string,
        invitationId: number
    ): Observable<InvitationSummaryModel> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_INVITATION_RECIPIENTS_SUMMARY,
            surveyId,
            invitationId
        );
        return this.$http.get(url).pipe(
            map((data: any) => new InvitationSummaryModel(data))
        );
    }

    markInvitationResponded(
        surveyId: string,
        invitationId: number,
        recipientIds: number[]
    ): Observable<any> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_INVITATION_RECIPIENTS_RESPONDED,
            surveyId,
            invitationId
        );
        return this.$http.post(url, recipientIds).pipe(
            map((data: any) => data)
        );
    }

    batchAddInvitationPanel(
        surveyId: string,
        invitationId: number,
        panel: any
    ): Observable<InvitationPanelCreateResultModel[]> {
        const query =  addQueryString({
            initialString: '',
            paramName: 'skip_invalid_results',
            paramValue: 'false'
        });
        
        const url: string = String.Format(
            ResourcesConsts.SURVEY_INVITATION_BATCH,
            surveyId,
            invitationId
        ) + query;

        return this.$http.post(url, panel).pipe(
            map((data: any) => createInvitationPanelResults(data))
        );
    }

    deleteInvitationPanel(
        surveyId: string,
        invitationId: number,
        panelId: any
    ): Observable<void> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_INVITATION_PANEL,
            surveyId,
            invitationId,
            panelId
        );
        return this.$http.delete(url).pipe(
            map(() => {
            })
        );
    }

    editInvitationSchedule(
        surveyId: string,
        invitationId: number,
        scheduleId: any,
        data: { scheduled_date: string }
    ): Observable<void> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_INVITATION_SCHEDULE,
            surveyId,
            invitationId,
            scheduleId
        );
        return this.$http.put(url, data).pipe(
            map(() => {
            })
        );
    }

    deleteInvitationSchedule(
        surveyId: string,
        invitationId: number,
        scheduleId: any
    ): Observable<void> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_INVITATION_SCHEDULE,
            surveyId,
            invitationId,
            scheduleId
        );
        return this.$http.delete(url).pipe(
            map(() => {
            })
        );
    }

    batchDeleteInvitationSchedule(
        surveyId: string,
        invitationId: number,
        scheduleIds: any
    ): Observable<void> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_INVITATION_SCHEDULES_DELETE,
            surveyId,
            invitationId
        );
        return this.$http
            .post<void>(url, scheduleIds);
    }

    batchDeleteInvitationPanels(
        surveyId: string,
        invitationId: number,
        panelIds: any
    ): Observable<void> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_INVITATION_PANELS,
            surveyId,
            invitationId
        );
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            body: panelIds
        };
        return this.$http.delete(url, options).pipe(
            map(() => {
            })
        );
    }

    createInvitation(
        surveyId: string,
        invitation: SurveyInvitation
    ): Observable<SurveyInvitation> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_INVITATION_BASICS,
            surveyId
        );
        return this.$http.post(url, invitation).pipe(
            map((data: any) => new SurveyInvitationModel(data))
        );
    }

    updateInvitation(
        surveyId: string,
        invitationId: number,
        invitation: SurveyInvitation
    ): Observable<SurveyInvitation> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_INVITATION,
            surveyId,
            invitationId
        );
        return this.$http.put(url, invitation).pipe(
            map((data: any) => new SurveyInvitationModel(data))
        );
    }

    getInvitationById(
        surveyId: string,
        invitationId: number
    ): Observable<SurveyInvitation> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_INVITATION,
            surveyId,
            invitationId
        );
        return this.$http.get(url).pipe(
            map((data: any) => new SurveyInvitationModel(data))
        );
    }

    deleteInvitationById(
        surveyId: string,
        invitationId: number
    ): Observable<void> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_INVITATION,
            surveyId,
            invitationId
        );
        return this.$http.delete(url).pipe(
            map((data: any) => data)
        );
    }

    getCompaniesList(): Observable<CompanyProfileModel[]> {
        const url: string = String.Format(ResourcesConsts.COMPANY_PROFILES);
        return this.$http.get(url).pipe(
            map((data: any) => createCompanyProfiles(data))
        );
    }

    newCompany(profile: CompanyProfile): Observable<CompanyProfileModel> {
        const url: string = String.Format(ResourcesConsts.COMPANY_PROFILES);
        return this.$http.post(url, profile).pipe(
            map((data: any) => new CompanyProfileModel(data))
        );
    }

    getMessageById(
        surveyId: string,
        invitationId: number
    ): Observable<InvitationMessageModel> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_INVITATION_MESSAGE,
            surveyId,
            invitationId
        );
        return this.$http.get(url).pipe(
            map((data: any) => new InvitationMessageModel(data))
        );
    }

    updateMessageById(
        surveyId: string,
        invitationId: number,
        newMessage: InvitationMessage
    ): Observable<InvitationMessageModel> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_INVITATION_MESSAGE,
            surveyId,
            invitationId
        );
        return this.$http.put(url, newMessage).pipe(
            map((data: any) => new InvitationMessageModel(data))
        );
    }

    getReminderById(
        surveyId: string,
        invitationId: number
    ): Observable<InvitationReminderModel> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_INVITATION_REMINDER,
            surveyId,
            invitationId
        );
        return this.$http.get(url).pipe(
            map((data: any) => new InvitationReminderModel(data))
        );
    }

    updateReminderById(
        surveyId: string,
        invitationId: number,
        newReminder: InvitationReminder
    ): Observable<InvitationReminderModel> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_INVITATION_REMINDER,
            surveyId,
            invitationId
        );
        return this.$http.put(url, newReminder).pipe(
            map((data: any) => new InvitationReminderModel(data))
        );
    }

    getSmsMessageLimits(
        surveyId: string,
        invitationId: number
    ): Observable<SmsMessageLimitsModel> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_SMS_INVITATION_MESSAGE_LIMITS,
            surveyId,
            invitationId
        );
        return this.$http.get(url).pipe(
            map((data: any) => new SmsMessageLimitsModel(data))
        );
    }

    sendToPending(surveyId: string, invitationId: string): Observable<void> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_INVITATION_SEND_PENDING,
            surveyId,
            invitationId
        );
        return this.$http.post(url, {}).pipe(
            map((data: any) => data)
        );
    }

    sendReminder(surveyId: string, invitationId: string): Observable<void> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_INVITATION_SEND_REMINDER,
            surveyId,
            invitationId
        );
        return this.$http.post(url, {}).pipe(
            map((data: any) => data)
        );
    }

    sendTestEmail(
        surveyId: string,
        invitationId: string,
        email: string
    ): Observable<void> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_INVITATION_SEND_TEST,
            surveyId,
            invitationId
        );
        return this.$http.post(url, { email }).pipe(
            map((data: any) => data)
        );
    }

    sendTestSms(
        surveyId: string,
        invitationId: string,
        phone_sms: string
    ): Observable<void> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_INVITATION_SEND_TEST,
            surveyId,
            invitationId
        );
        return this.$http.post(url, { phone_sms }).pipe(
            map((data: any) => data)
        );
    }

    copyInvitation(
        surveyId: string,
        invitationId: string,
        invitationCopyData: any
    ): Observable<SurveyInvitationModel> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_INVITATION_COPY,
            surveyId,
            invitationId
        );
        return this.$http.post(url, invitationCopyData).pipe(
            map((data: any) => new SurveyInvitationModel(data))
        );
    }

    invitationOptOut(
        survey_id: number,
        recipient_external_id: string,
        status: string
    ) {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_INVITATION_OPT_OUT,
            survey_id
        );
        return this.$http
            .post(url, { survey_id, recipient_external_id, status })
            .pipe(
                map((data: any) => data)
            );
    }

    exportInvitationDetails(
        surveyId: string,
        invitationId: string
    ): Observable<any> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_EXPORT_INVITATION_DETAILS,
            surveyId,
            invitationId
        );
        return this.fileExportProvider.getFileFromBackend(url);
    }
}
