import { activeToggleData } from '../../app-admin/consts/toggle.const';
import { PagedList } from './index';
import * as _ from 'lodash';
import {
    createValuedList,
    emptyPagedList,
    ModelImplementor,
    ModelPageListImplementor
} from '../../app-admin/models/implementor.model';
import { MapperType } from './survey-settings.model';
import { PageRequestOptions } from './index';

export interface Contact {
    id: string;
    password?: string;
    email?: string;
    phone_sms?: string;
    status?: string;
    roles?: string[];
    groups?: any[];
    profile_properties?: ContactProfile[];
    isCurrentlyLogged?: boolean;
    firstName?: string;
    isUsed?: boolean;
    override_active_directory_mapping?: boolean;
    send_welcome_email?: boolean;
    getProfileProperty?: Function;
    setProfileProperty?: Function;
}

export interface ContactData {
    contact_created: number;
    contact_updated: number;
}


export interface ContactProfile {
    property_id?: number;
    property_name: string;
    value: string;
}

export interface GroupSource {
    source_id: string;
    source_type: string;
}

export interface ContactGroup {
    id: string;
    name: string;
    description: string;
    member_count: number;
    can_edit?: boolean;
    source: GroupSource;
}

export interface ContactGroupPageRequestOptions extends PageRequestOptions {
    permission: string;
    includeListData: boolean;
}

export interface Contacts extends PagedList<Contact> {}

export interface ContactGroupList extends PagedList<ContactGroup> {}

export const emptyContactGroupList = (): ContactGroupList =>
    emptyPagedList<ContactGroup>();

export const emptyGroupSource = (): GroupSource => ({
    source_id: '',
    source_type: ''
});

export const createContactGroups = (groups: any): ContactGroup[] =>
    createValuedList<ContactGroup>(groups, ContactGroupModel);

export const createContacts = (contacts: any): Contact[] =>
    createValuedList<Contact>(contacts, ContactModel);

export const createContactProfiles = (
    profiles: any
): CreateProfilePropertyModel[] =>
    createValuedList(profiles, CreateProfilePropertyModel);

export function createRoles(roles: any): string[] {
    return roles && roles.length
        ? _.map(roles, (role: any) => (typeof role === 'string' ? role : ''))
        : [];
}

export class ContactsModel extends ModelPageListImplementor<Contact>
    implements Contacts {
    constructor(options: any) {
        super(options, ContactModel);
    }
}

// TODO: should be refactored in whole code in order to replace by ContactModel
export class CreateContactModel implements Contact {
    id: string;
    password: string;
    email: string;
    phone_sms: string;
    status: string;
    roles: string[];
    profile_properties: CreateProfilePropertyModel[];
    groups: string[];
    send_welcome_email: boolean;

    constructor(options: any = {}) {
        const defaultOptions = {
            id: '',
            email: '',
            phone_sms: '',
            status: activeToggleData.off,
            roles: [],
            send_welcome_email: false,
            profile_properties: []
        };

        this.id = options.id || defaultOptions.id;
        this.password = options.password;
        this.email = options.email || defaultOptions.email;
        this.phone_sms = options.phone_sms || defaultOptions.phone_sms;
        this.status = options.status || defaultOptions.status;
        this.roles = options.roles || defaultOptions.roles;
        this.profile_properties = options.profile_properties || defaultOptions.profile_properties;
        this.groups = [];
        this.send_welcome_email = options.send_welcome_email || defaultOptions.send_welcome_email;
    }
}

// Contact Model is the class, which is adapted to inheritance model of other component (Contact list, infinite table, etc
export class ContactModel extends ModelImplementor implements Contact {
    id: string;
    password?: string;
    email?: string;
    phone_sms? :string;
    status?: string;
    roles?: string[];
    groups?: ContactGroup[];
    profile_properties?: ContactProfile[];
    isCurrentlyLogged?: boolean;
    override_active_directory_mapping?: boolean;
    send_welcome_email?: boolean;
    name?: string;
    can_edit?: boolean;
    constructor(options: any) {
        const mapper: MapperType[] = [
            { in: 'id', out: 'id', type: 'string' },
            { in: 'password', out: 'password', type: 'string' },
            { in: 'email', out: 'email', type: 'string' },
            { in: 'phone_sms', out: 'phone_sms', type: 'string' },
            { in: 'status', out: 'status', type: 'string' },
            { in: 'roles', out: 'roles', type: 'any' },
            { in: 'groups', out: 'groups', type: 'any' },
            { in: 'profile_properties', out: 'profile_properties', type: 'any' },
            { in: 'override_active_directory_mapping', out: 'override_active_directory_mapping', type: 'boolean' },
            { in: 'send_welcome_email', out: 'send_welcome_email', type: 'boolean' },
            { in: 'name', out: 'name', type: 'string' },
            { in: 'can_edit', out: 'can_edit', type: 'boolean' }
        ];

        super(options, mapper);
    }

    public getProfileProperty(propName: string): any {
        if (this.profile_properties) {
            const foundProp = _.find(
                this.profile_properties,
                property => property.property_name === propName
            );
            return _.get(foundProp, 'value', undefined);
        }
    }

    public setProfileProperty(propName: string, value: any) {
        if (this.profile_properties) {
            const foundProp = _.find(
                this.profile_properties,
                property => property.property_name === propName
            );
            if (foundProp) {
                foundProp.value = value;
            }
        }
    }
}

export class EditCurrentContactModel {
    current_password: string;
    password: string;
    email: string;
    phone_sms: string;
    profile_properties: CreateProfilePropertyModel[];

    constructor(options: any = {}) {
        const defaultOptions = {
            email: '',
            phone_sms: ''
        };

        this.current_password = options.current_password;
        this.password = options.password;
        this.email = options.email || defaultOptions.email;
        this.phone_sms = options.phone_sms || defaultOptions.phone_sms;
        this.profile_properties = [];
    }
}

export class ContactGroupModel extends ModelImplementor
    implements ContactGroup {
    id: string;
    name: string;
    description: string;
    member_count: number;
    can_edit: boolean;
    source: GroupSource;

    constructor(options: any) {
        const mapper: MapperType[] = [
            { in: 'id', out: 'id', type: 'string' },
            { in: 'name', out: 'name', type: 'string' },
            { in: 'description', out: 'description', type: 'string' },
            { in: 'member_count', out: 'member_count', type: 'number' },
            { in: 'can_edit', out: 'can_edit', type: 'boolean' },
            { in: 'source', out: 'source', type: 'any' }
        ];

        super(options, mapper);
    }
}

export class CreateProfilePropertyModel extends ModelImplementor
    implements ContactProfile {
    property_id: number;
    property_name: string;
    is_hidden: boolean;
    value: string;

    constructor(options: any) {
        const mapper: MapperType[] = [
            { in: 'property_id', out: 'property_id', type: 'number' },
            { in: 'property_name', out: 'property_name', type: 'string' },
            { in: 'is_hidden', out: 'is_hidden', type: 'boolean' },
            { in: 'value', out: 'value', type: 'string' }
        ];
        super(options, mapper);
    }
}

export class CreateContactGroupList
    extends ModelPageListImplementor<ContactGroup>
    implements ContactGroupList {
    constructor(options: any) {
        super(options, ContactGroupModel);
    }
}

export interface ContactSource {
    source_id: string;
    source_type: string;
}

export type ContactSources = Array<ContactSource>;

export interface MembershipItem {
    id: string;
    name: string;
    list_item_type: string;
}

export interface MembershipItems extends PagedList<MembershipItem> {}

export class MembershipsModel extends ModelPageListImplementor<MembershipItem>
    implements MembershipItems {
    constructor(options: any) {
        super(options, MembershipModel);
    }
}

export class MembershipModel extends ModelImplementor
    implements MembershipItem {
    id: string;
    name: string;
    list_item_type: string;
    constructor(options: any) {
        const mapper: MapperType[] = [
            { in: 'id', out: 'id', type: 'string' },
            { in: 'name', out: 'name', type: 'string' },
            { in: 'list_item_type', out: 'list_item_type', type: 'string' }
        ];

        super(options, mapper);
    }
}

export class ContactExportCsvOptions {
    group_ids: string[];
}
