import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { ResourcesConsts } from '../consts/resources.const';
import { EnvironmentProvider } from './environment.provider';
import { Guid } from '../helpers/guid.helper';
import { generateUniversalLink } from '../helpers/link-generator.helper';

@Injectable()
export class ExternalLoginProvider {
    constructor(
        private environmentProvider: EnvironmentProvider) {}

    getGoogleSinginLink(account :string): string {
        const url = ResourcesConsts.GOOGLE_SIGNIN;
        const params = new HttpParams()
            .set('client_id', this.environmentProvider.googleAuthClientId)
            .set('redirect_uri', `${generateUniversalLink('login')}`)
            .set('response_type', 'id_token')
            .set('nonce', Guid.newGuid())
            .set('scope', 'email')
            .set('state', `account=${(account ?? '')}`);

        return `${url}?${params.toString()}`;
    }
}
