import { map, mergeMap } from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';
import { AccountNameResolverProvider } from '../../infrastructure/providers/account-name-resolver.provider';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

import { LoginProvider } from '../../app-admin/providers';
import { JWTProvider, StorageProvider } from '../../infrastructure/providers';
import { environment } from '../../environments/environment';
import { SsoService } from '../../infrastructure/services/sso.service';
import { EnvironmentProvider } from '../../infrastructure/providers/environment.provider';
import * as _ from 'lodash';
import { ApplicationLanguageService } from '../../infrastructure/services/application-language.service';
import { JWTSignInEffectData } from '../../infrastructure/models';
import { JWTSignInType } from '../../infrastructure/models/signInWithJWT.model';

@Injectable()
export class AccountNameResolverGuard implements CanActivate {
    constructor(
        private accountNameResolverProvider: AccountNameResolverProvider,
        private loginProvider: LoginProvider,
        private storageProvider: StorageProvider,
        private ssoService: SsoService,
        private environmentProvider: EnvironmentProvider,
        private applicationLanguageService: ApplicationLanguageService,
        private jwtProvider: JWTProvider,
        private router: Router
    ) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> {
        if (environment.name === 'server') {
            return this.handleAutologin(route);
        }
        return this.resolveAccountName().pipe(mergeMap((isResolved) => {
            if (!isResolved) {
                this.router.navigate(['/application-not-active'], { skipLocationChange: true });
                return of(false);
            }

            return this.handleAutologin(route);
        }));
    }

    preventAutologinAccordingToQueryParams(route: ActivatedRouteSnapshot): boolean {
        const excludedQueryParams = ['jwt', 'i', 'rk', 'iid'];
        return !!_.chain(route.queryParams).keys().intersection(excludedQueryParams).value().length;
    }

    handleAutologin(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> {
        if (
            this.ssoService.firstAttemptStarted ||
            this.preventAutologinAccordingToQueryParams(route)
        ) {
            if (route.queryParams.jwt) {
                return this.startJWTLogin(route);
            }
            return of(true);
        } else if (this.environmentProvider.disableSSO) {
            return this.applicationLanguageService.initLanguageSettings();
        } else {
            return from(this.ssoService.takeSurveyAutologin()).pipe(
                mergeMap(done => this.applicationLanguageService.initLanguageSettings())
            );
        }
    }

    startJWTLogin(route: ActivatedRouteSnapshot) {
        const queryParams = route.queryParams;
        return this.jwtProvider.initAppWithJWT(this.getJWTSignInEffectData(queryParams))
            .pipe(
                map(() => {
                    return true;
                })
            );
    }

    private getJWTSignInEffectData(queryParams):JWTSignInEffectData {
        const { internal_access, jwt, destinationURL, admin_redirect } = queryParams;

        let jwtType;
        if (internal_access)
            jwtType = JWTSignInType.MasterSite;
        else if (admin_redirect) 
            jwtType = JWTSignInType.AdminAppRedirect;
        else
            jwtType = JWTSignInType.SingleSignOn;

        return {
            token: jwt,
            jwtType: jwtType,
            destinationURL: destinationURL
        }
    }

    resolveAccountName(): Observable<boolean> {
        return this.accountNameResolverProvider.getTakeSurveyAccount().pipe(
            mergeMap((account: string) => {
                if (account)
                    return of(true);

                return this.loginProvider.resolveAccountName().pipe(
                    mergeMap(acc => {
                        if (!acc || !acc.account_name)
                            return of(false);

                        this.accountNameResolverProvider.saveTakeSurveyAccount(
                            acc.account_name
                        );
                        this.storageProvider.saveAccount(acc.account_name);
                        return of(true);
                    })
                );
            })
        );
    }
}
