import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ResponseItem, ResponsePage } from '../models';
import {
    SurveyQuestionType,
    SurveySliderValueType
} from '../../infrastructure/consts/surveys.consts';
import { SurveyPage } from '../../infrastructure/models/survey-page.model';
import { getEnabledChoices } from '../../infrastructure/helpers/surveys.helper';

@Injectable()
export class SurveyResponseRestoreService {
    restorePageItems(page: SurveyPage, responsePage: ResponsePage) {
        if (page && responsePage) {
            return _.map(page.items, item => {
                const responseItem = _.find(responsePage.items, {
                    item_id: item.id
                }) as ResponseItem;
                const answer = responseItem && responseItem.answer;
                return this.restoreItemDependingOnItemType(item, answer);
            });
        }
    }

    private restoreItemDependingOnItemType(item, answer) {
        if (answer) {
            switch (item.item_type) {
                case SurveyQuestionType.SINGLE_LINE_TEXT:
                case SurveyQuestionType.MULTI_LINE_TEXT:
                    item.default_text = answer.text;
                    return item;
                case SurveyQuestionType.CHECKBOXES:
                    item.choices = _.map(item.choices, choice => {
                        const answerChoice = _.find(answer.choices, {
                            choice_id: choice.id
                        }) as any;
                        choice.is_default = !!answerChoice;
                        if (answerChoice && choice.is_other) {
                            choice.other_value = answerChoice.text;
                        }
                        return choice;
                    });
                    return item;
                case SurveyQuestionType.IMAGE_CHOICE:
                    item.choices = _.map(item.choices, choice => {
                        const answerChoice = _.find(answer.choices, {
                            choice_id: choice.id
                        }) as any;
                        choice.is_default = !!answerChoice;
                        return choice;
                    });
                    return item;
                case SurveyQuestionType.RADIOBUTTONS:
                case SurveyQuestionType.DROPDOWNLIST:
                case SurveyQuestionType.NET_PROMOTER_SCORE:
                    item.choices = _.map(item.choices, choice => {
                        const isSelected = answer.choice_id === choice.id;
                        choice.is_default = !!isSelected;
                        if (choice.is_other && answer.text) {
                            item.other_value = answer.text;
                        }
                        return choice;
                    });
                    return item;
                case SurveyQuestionType.CUSTOM_SOURCE_DROPDOWNLIST:
                    item.value = answer.value;
                    return item;
                case SurveyQuestionType.RATINGSCALE:
                    item.choice_id = answer.choice_id;
                    return item;
                case SurveyQuestionType.RATING:
                    item.selectedScore = _.findIndex(
                        getEnabledChoices(item.choices), { id: answer.choice_id }) + 1;
                    return item;
                case SurveyQuestionType.SLIDER:
                    if (
                        item.value_type === SurveySliderValueType.NUMBER_RANGE
                    ) {
                        item.value = +answer.value;
                    } else {
                        const enabled_choices = getEnabledChoices(item.choices);
                        item.value = _.findIndex(enabled_choices, { id: answer.choice_id }) + 1;
                    }
                    return item;
                case SurveyQuestionType.RANKORDER:
                    // this item is restored inside rank order item
                    return item;
                case SurveyQuestionType.MAXDIFF:
                    // this item is being restored inside MaxDiffPreviewComponent
                    return item;
                case SurveyQuestionType.FILE_UPLOAD:
                case SurveyQuestionType.SIGNATURE:
                    if (answer.file) {
                        item.file_name = _.get(answer, 'file.file_name');
                        item.file_id = _.get(answer, 'file.id');
                        item.file_size = _.get(answer, 'file.content_size');
                    }
                    return item;
                case SurveyQuestionType.MATRIX:
                    // this item is being restored inside MatrixPreviewItemComponent
                    return item;
                case SurveyQuestionType.CONTACT_FORM:
                    // this item is being restored inside ContactFormPreviewItemComponent
                    return item;
                default:
                    break;
            }
        }
    }
}
