import { MapperType } from '../../../../infrastructure/models/survey-settings.model';
import { ModelImplementor } from '../../../models/implementor.model';

export enum RootRedirectOption {
    DEFAULT = 'ShowDefaultPage',
    BLANK = 'ShowBlankPage',
    TAKE_SURVEY_LOGIN = 'RedirectToTakeSurveyLoginPage',
    ADMIN_LOGIN = 'RedirectToAdminLoginPage',
    CUSTOM_URL = 'RedirectToCustomUrl',
    DISPLAY_MESSAGE = 'DisplayMessage'
}

export interface PreferenceApplicationSettings {
    show_available_surveys: boolean;
    show_available_reports: boolean;
    enable_email_validation: any;
    time_zone_utc_offset_in_hours: number;
    time_zone_id: string;
    time_zone_confirmed: boolean;
    root_redirect_option: RootRedirectOption;
    root_redirect_message: string;
    root_redirect_custom_url: string;
}

export class PreferenceApplicationSettingsModel extends ModelImplementor
    implements PreferenceApplicationSettings {
    enable_email_validation: any;
    show_available_reports: boolean;
    show_available_surveys: boolean;
    time_zone_utc_offset_in_hours: number;
    time_zone_id: string;
    time_zone_confirmed: boolean;
    root_redirect_option: RootRedirectOption;
    root_redirect_message: string;
    root_redirect_custom_url: string;

    constructor(options: any = {}) {
        const mapper: MapperType[] = [
            {
                in: 'show_available_surveys',
                out: 'show_available_surveys',
                type: 'any'
            },
            {
                in: 'show_available_reports',
                out: 'show_available_reports',
                type: 'any'
            },
            {
                in: 'enable_email_validation',
                out: 'enable_email_validation',
                type: 'any'
            },
            { in: 'time_zone_utc_offset_in_hours', out: 'time_zone_utc_offset_in_hours', type: 'any' },
            { in: 'time_zone_id', out: 'time_zone_id', type: 'any' },
            { in: 'time_zone_confirmed', out: 'time_zone_confirmed', type: 'any' },
            {
                in: 'root_redirect_message',
                out: 'root_redirect_message',
                type: 'any'
            },
            {
                in: 'root_redirect_custom_url',
                out: 'root_redirect_custom_url',
                type: 'any'
            },
            {
                in: 'root_redirect_option',
                out: 'root_redirect_option',
                type: 'any'
            }
        ];
        super(options, mapper);
    }
}

export interface AppTimeZone {
    id: string;
    name: string;
    supports_daylight_saving_time: boolean;
    utc_offset_in_hours: number;
}
