import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginProvider } from '../../providers';
import { RegistrationSettings } from '../../models';
import { SurveyDefaultText } from '../../../infrastructure/consts/surveyDefaultText';
import { SharedService } from '../../../infrastructure/services/shared.service';

@Component({
    selector: 'cb-take-survey-login',
    templateUrl: './take-survey-login.component.html',
    styleUrls: ['./take-survey-login.component.scss']
})
export class TakeSurveyLoginComponent implements OnInit {
    public form: FormGroup;
    public account: string;
    public settings: RegistrationSettings;
    @Output() login: EventEmitter<any> = new EventEmitter();
    surveyText = SurveyDefaultText;
    SurveyDefaultText = SurveyDefaultText;

    constructor(
        private fb: FormBuilder,
        private loginProvider: LoginProvider,
        private sharedService: SharedService
    ) {}

    ngOnInit() {
        this.form = this.fb.group({
            id: ['', Validators.required],
            password: ['', Validators.required]
        });
        this.sharedService.surveyText.subscribe(text => {
            this.surveyText = { ...text };
        });

        this.getSurveySettings();
    }

    getSurveySettings() {
        this.loginProvider.getRegistrationSettings().subscribe(settings => {
            this.settings = settings;
        });
    }

    onSubmit() {
        if (this.form.valid) {
            this.login.emit(this.form.value);
        }
    }
}
